/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PaginatedTourListResponse } from '../../models/paginated-tour-list-response';

export interface GetTours$Params {

/**
 * Pagination parameter. Skips page * size entries.
 */
  page?: number;

/**
 * Max number of results per page.
 */
  size?: number;

/**
 * Paramter to sort by as field(:(asc|desc))?. If missing default sort is used. field is the name in the db model not the db field name.
 */
  sort?: Array<string>;

/**
 * The from date
 */
  from?: string;

/**
 * The to date
 */
  to?: string;

/**
 * Tours with associated vehicles.
 */
  vehicle_id?: string;

/**
 * Filter tours by current status (STARTED, SCHEDULED, FINISHED)
 */
  statuses?: Array<'STARTED' | 'SCHEDULED' | 'FINISHED'>;

/**
 * Specify whether to include unassigned associated cases in response
 */
  include_all_cases?: boolean;
}

export function getTours(http: HttpClient, rootUrl: string, params?: GetTours$Params, context?: HttpContext): Observable<StrictHttpResponse<PaginatedTourListResponse>> {
  const rb = new RequestBuilder(rootUrl, getTours.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":true});
    rb.query('size', params.size, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
    rb.query('from', params.from, {"style":"form","explode":true});
    rb.query('to', params.to, {"style":"form","explode":true});
    rb.query('vehicle_id', params.vehicle_id, {"style":"form","explode":true});
    rb.query('statuses', params.statuses, {"style":"simple","explode":false});
    rb.query('include_all_cases', params.include_all_cases, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PaginatedTourListResponse>;
    })
  );
}

getTours.PATH = '/api/v1/tours';
