import { Injectable, inject } from '@angular/core';
import {
  Location,
  VehicleListResponse,
  WebSocketLocationUpdateMessage,
} from '@dispo-shared/open-api/models';
import { Vehicle } from '@dispo-shared/open-api/models/vehicle';
import { VehiclesService } from '@dispo-shared/open-api/services';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

export interface VehicleLocation {
  vehicle: Vehicle | null;
  location: Location | undefined;
  currentTourId?: string;
  currentTourNaturalId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class VehiclesLocationsService {
  private readonly _vehicleLocationsSource = new BehaviorSubject<VehicleLocation[]>([]);
  readonly vehicleLocations$ = this._vehicleLocationsSource.asObservable();
  private readonly vehiclesService: VehiclesService = inject(VehiclesService);

  fetchLocationsForAllVehiclesOnTour(): Observable<VehicleLocation[]> {
    return this.vehiclesService
      .getVehicles({ currently_on_tour: true, include_last_location: true })
      .pipe(
        map((data: VehicleListResponse) => {
          this._udapteVehicleLocationsSource(data.vehicles ?? []);
          return this._vehicleLocationsSource.value;
        }),
        catchError((error) => {
          console.error(error);
          return throwError(error);
        })
      ) as Observable<VehicleLocation[]>;
  }

  public updateVehicleLocationFromWebSocketMessage(
    locationUpdateMessage: WebSocketLocationUpdateMessage
  ): void {
    const vehicleId = locationUpdateMessage.vehicle_id;

    if (
      vehicleId !== undefined &&
      locationUpdateMessage.lat !== undefined &&
      locationUpdateMessage.lon !== undefined
    ) {
      const tmpVehicleLocations = [...this._vehicleLocationsSource.value];
      tmpVehicleLocations.forEach((vehicleLocation) => {
        if (vehicleLocation.vehicle?.id === vehicleId && vehicleLocation.location !== undefined) {
          vehicleLocation.location.lat = locationUpdateMessage.lat as number;
          vehicleLocation.location.lon = locationUpdateMessage.lon as number;
        }
      });
      this._vehicleLocationsSource.next(tmpVehicleLocations);
    }
  }

  private _udapteVehicleLocationsSource(vehicles: Vehicle[]): void {
    const tmpVehicleLocations = new Array<VehicleLocation>();

    vehicles.forEach((vehicle) => {
      tmpVehicleLocations.push({
        vehicle: vehicle,
        location: vehicle.location,
        currentTourId: vehicle.current_tour_id,
        currentTourNaturalId: vehicle.current_tour_natural_id?.toString(),
      });
    });
    this._vehicleLocationsSource.next(tmpVehicleLocations);
  }
}
