import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TenantsService } from '@dispo-shared/open-api/services/tenants.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { TenantMeta } from '@dispo-shared/open-api/models/tenant-meta';
import { UrlHelper } from '@dispo-shared/shared-ui/helper/url-helper/url-helper';

interface TenantConfig {
  phone_number_login_enabled: boolean;
  logo_default: string;
  favicon: string;
  colors?: { [key: string]: string };
}

@Injectable({
  providedIn: 'root',
})
export class TenantConfigService {
  private http = inject(HttpClient);
  private tenantService = inject(TenantsService);
  private authService = inject(AuthService);
  private router = inject(Router);

  public loaded!: Promise<boolean>;
  private promiseResolve = (_value: boolean) => {};

  // Tenant Meta
  private _tenantMeta: WritableSignal<TenantMeta> = signal({});
  public tenantMeta: Signal<TenantMeta> = this._tenantMeta.asReadonly();
  // Tenant Config
  private _tenantConfig: WritableSignal<TenantConfig> = signal({
    phone_number_login_enabled: true,
    logo_default: 'assets/img/logo_default.png',
    favicon: 'favicon_default.ico',
  });
  private _appVersion: WritableSignal<string | null> = signal(null);

  public tenantConfig: Signal<TenantConfig> = this._tenantConfig.asReadonly();
  public tenantShortCode: WritableSignal<string | null> = signal(null);
  // Default Route
  public defaultRoute: Signal<string> = computed(() =>
    this.tenantMeta().type === 'ROADSIDE_ASSISTANCE' ? '/rsa' : '/fnrl'
  );
  public appVersion: Signal<string | null> = this._appVersion.asReadonly();

  constructor() {
    this.loaded = new Promise((resolve) => {
      this.promiseResolve = resolve;
    });

    let getTenantConfig = null;
    if (this.shouldLookupTenantByShortCode) {
      getTenantConfig = this.tenantService.getTenantByShortCode({
        short_code: UrlHelper.firstSubdomainFromWindowLocation() ?? '',
      });
    } else {
      getTenantConfig = this.tenantService.getTenantByDomain({
        tenant_domain: window.location.href,
      });
    }

    getTenantConfig.subscribe({
      next: ({ frontend_config, short_code }) => {
        // set tenant meta
        if (frontend_config?.tenant_meta) {
          this.setTenantMeta(frontend_config.tenant_meta);
          const tenantKey = frontend_config.tenant_meta.key;
          if (tenantKey) this.authService.setTenantKey(tenantKey);
        }

        // set tenant config
        if (frontend_config?.config) {
          this.setTenantConfig(frontend_config.config as TenantConfig);
        }

        // set tenant short code
        if (short_code) {
          this.tenantShortCode.set(short_code);
        }
        this.promiseResolve(true);
      },
      error: () => {
        console.error('Tenant not found');
        this.promiseResolve(false);
        if (window.location.pathname !== '/signup') {
          this.router.navigateByUrl('/tenant-not-found');
        }
      },
    });
  }

  /**
   * Checks if the given file url is exists in the app
   *
   * @param {string} url location of the file
   * @returns {Observable<boolean>} True or false whether the file exists or not
   */
  fileExists(url: string): Observable<boolean> {
    return this.http
      .get<boolean>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  getServices(): Observable<{ key: string; title: string }[]> {
    const tenantShortCode = this.tenantShortCode();
    if (tenantShortCode) {
      return this.tenantService
        .getTenantMasterData({ tenant_short_code: tenantShortCode })
        .pipe(
          map((tenantData) => tenantData?.['master-data-items']?.[0]?.data?.['service_types'] ?? [])
        );
    }

    return of([]);
  }

  setTenantMeta(tenantMeta: TenantMeta): void {
    this._tenantMeta.set(tenantMeta);
  }

  setTenantConfig(tenantConfig: TenantConfig): void {
    const { phone_number_login_enabled, logo_default, favicon, colors } = tenantConfig ?? {};
    this._tenantConfig.set({
      ...this._tenantConfig(),
      ...(phone_number_login_enabled != null && { phone_number_login_enabled }),
      ...(logo_default && { logo_default }),
      ...(favicon && { favicon }),
      ...(colors && { colors }),
    });
  }

  setAppVersion(version: string): void {
    this._appVersion.set(version);
  }

  private get shouldLookupTenantByShortCode(): boolean {
    const host = window.location.hostname;
    if (host.includes('tourfold.com') || host.includes('dispo.xyz') || host.includes('localhost')) {
      return true;
    }
    return false;
  }
}
