import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { VehiclesState } from '../../../general/store/reducers/vehicles.reducer';
import {
  AddVehicle,
  AddVehicles,
  SetStatus,
  SetVehicleTypes,
  UpdateVehicle,
} from '../../../general/store/actions/vehicles.action';
import { Status } from '../../../general/interfaces/status.enum';
import { VehicleSidebarState } from '../../../general/store/reducers/vehicle-sidebar.reducer';
import {
  SetAssociationSidebarRequestStatus,
  SetManageDialogRequestStatus,
} from '../../../general/store/actions/vehicle-sidebar.action';
import { VehiclesService, VehicleTypesService } from '@dispo-shared/open-api/services';
import { Vehicle, VehicleCreateRequest, VehicleUpdateRequest } from '@dispo-shared/open-api/models';

@Injectable({
  providedIn: 'root',
})
export class VehiclesStoreService {
  private vehicleStore = inject<Store<VehiclesState>>(Store);
  private vehicleSidebarStore = inject<Store<VehicleSidebarState>>(Store);
  private vehicleService = inject(VehiclesService);
  private vehicleTypesService = inject(VehicleTypesService);

  getAllVehicle(): void {
    this.vehicleStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.vehicleService
      .getVehicles({
        size: 1000,
        sort: ['createdAt'],
      })
      .subscribe({
        next: (response) => {
          if (response && response.vehicles) {
            this.vehicleStore.dispatch(new AddVehicles({ vehicles: response.vehicles }));
            this.vehicleStore.dispatch(new SetStatus({ status: Status.Success }));
          } else {
            this.vehicleStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'No vehicles object in the response',
                  time: Date.now.toString(),
                  title: 'Empty response',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[VehicleService][getAllVehicle]`, error);
          this.vehicleStore.dispatch(new SetStatus({ status: Status.Failed, error }));
        },
      });
  }

  createVehicle(vehicle: VehicleCreateRequest): void {
    this.vehicleSidebarStore.dispatch(new SetManageDialogRequestStatus({ status: Status.Pending }));
    this.vehicleService.createVehicle({ body: vehicle }).subscribe({
      next: (response) => {
        if (response) {
          this.vehicleStore.dispatch(new AddVehicle({ vehicle: response }));
          this.vehicleSidebarStore.dispatch(
            new SetManageDialogRequestStatus({ status: Status.Success })
          );
        } else {
          this.vehicleSidebarStore.dispatch(
            new SetManageDialogRequestStatus({
              status: Status.Failed,
              error: 'No response object',
            })
          );
        }
      },
      error: (error) => {
        console.error(`[VehicleService][createVehicle]`, error);
        this.vehicleSidebarStore.dispatch(
          new SetManageDialogRequestStatus({ status: Status.Failed, error })
        );
      },
    });
  }

  editVehicle(vehicleId: string, vehicle: VehicleUpdateRequest): void {
    this.vehicleSidebarStore.dispatch(new SetManageDialogRequestStatus({ status: Status.Pending }));
    this.vehicleService
      .updateVehicle({
        id: vehicleId,
        body: vehicle,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.vehicleStore.dispatch(new UpdateVehicle({ vehicle: response }));
            this.vehicleSidebarStore.dispatch(
              new SetManageDialogRequestStatus({ status: Status.Success })
            );
          } else {
            this.vehicleSidebarStore.dispatch(
              new SetManageDialogRequestStatus({
                status: Status.Failed,
                error: 'No response object',
              })
            );
          }
        },
        error: (error) => {
          console.error(`[VehicleService][editVehicle]`, error);
          this.vehicleSidebarStore.dispatch(
            new SetManageDialogRequestStatus({ status: Status.Failed, error })
          );
        },
      });
  }

  changeAssociation(vehicle: Vehicle, device_id: string): void {
    if (!vehicle.id) {
      return;
    }

    this.vehicleSidebarStore.dispatch(
      new SetAssociationSidebarRequestStatus({ status: Status.Pending })
    );

    this.vehicleService
      .associateDevice({
        id: vehicle.id,
        body: { device_id },
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.vehicleStore.dispatch(
              new UpdateVehicle({ vehicle: response, oldVehicle: vehicle })
            );
            this.vehicleSidebarStore.dispatch(
              new SetAssociationSidebarRequestStatus({ status: Status.Success })
            );
          } else {
            this.vehicleSidebarStore.dispatch(
              new SetAssociationSidebarRequestStatus({
                status: Status.Failed,
                error: 'No response object',
              })
            );
          }
        },
        error: (error) => {
          console.error(`[VehicleService][editVehicle]`, error);
          this.vehicleSidebarStore.dispatch(
            new SetAssociationSidebarRequestStatus({
              status: Status.Failed,
              error,
            })
          );
        },
      });
  }

  getVehicleById(id: string): void {
    this.vehicleStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.vehicleService
      .getVehicle({
        id,
      })
      .subscribe({
        next: (response) => {
          if (response) {
            this.vehicleStore.dispatch(new UpdateVehicle({ vehicle: response }));
            this.vehicleStore.dispatch(new SetStatus({ status: Status.Success }));
          } else {
            this.vehicleStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'No devices object in the response',
                  time: Date.now.toString(),
                  title: 'Empty response',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[VehicleService][getVehicleById]`, error);
          this.vehicleStore.dispatch(new SetStatus({ status: Status.Failed, error }));
        },
      });
  }

  getVehicleTypes(): void {
    this.vehicleTypesService.getVehicleTypes().subscribe({
      next: (res) => {
        if (res && res.vehicleTypes) {
          this.vehicleStore.dispatch(new SetVehicleTypes({ vehicleTypes: res.vehicleTypes }));
        }
      },
      error: (error) => {
        console.error(`[VehicleService][getVehicleTypes]`, error);
        this.vehicleStore.dispatch(new SetStatus({ status: Status.Failed, error }));
      },
    });
  }
}
