/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CalendarEvent } from '../models/calendar-event';
import { CalendarEventListResponse } from '../models/calendar-event-list-response';
import { createCalendarEvent } from '../fn/calendar-events/create-calendar-event';
import { CreateCalendarEvent$Params } from '../fn/calendar-events/create-calendar-event';
import { deleteCalendarEvent } from '../fn/calendar-events/delete-calendar-event';
import { DeleteCalendarEvent$Params } from '../fn/calendar-events/delete-calendar-event';
import { getCalendarEvents } from '../fn/calendar-events/get-calendar-events';
import { GetCalendarEvents$Params } from '../fn/calendar-events/get-calendar-events';
import { updateCalendarEvent } from '../fn/calendar-events/update-calendar-event';
import { UpdateCalendarEvent$Params } from '../fn/calendar-events/update-calendar-event';

@Injectable({ providedIn: 'root' })
export class CalendarEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCalendarEvents()` */
  static readonly GetCalendarEventsPath = '/api/v1/calendars/default/events';

  /**
   * Get calendar events from default calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarEvents$Response(params?: GetCalendarEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventListResponse>> {
    return getCalendarEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get calendar events from default calendar.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalendarEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarEvents(params?: GetCalendarEvents$Params, context?: HttpContext): Observable<CalendarEventListResponse> {
    return this.getCalendarEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEventListResponse>): CalendarEventListResponse => r.body)
    );
  }

  /** Path part for operation `createCalendarEvent()` */
  static readonly CreateCalendarEventPath = '/api/v1/calendars/default/events';

  /**
   * Create new event within calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalendarEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarEvent$Response(params: CreateCalendarEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEvent>> {
    return createCalendarEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new event within calendar.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalendarEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarEvent(params: CreateCalendarEvent$Params, context?: HttpContext): Observable<CalendarEvent> {
    return this.createCalendarEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEvent>): CalendarEvent => r.body)
    );
  }

  /** Path part for operation `deleteCalendarEvent()` */
  static readonly DeleteCalendarEventPath = '/api/v1/calendars/default/events/{id}';

  /**
   * Delete calendar event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalendarEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarEvent$Response(params: DeleteCalendarEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCalendarEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete calendar event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCalendarEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarEvent(params: DeleteCalendarEvent$Params, context?: HttpContext): Observable<void> {
    return this.deleteCalendarEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCalendarEvent()` */
  static readonly UpdateCalendarEventPath = '/api/v1/calendars/default/events/{id}';

  /**
   * Update calendar event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCalendarEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarEvent$Response(params: UpdateCalendarEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEvent>> {
    return updateCalendarEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Update calendar event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCalendarEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarEvent(params: UpdateCalendarEvent$Params, context?: HttpContext): Observable<CalendarEvent> {
    return this.updateCalendarEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarEvent>): CalendarEvent => r.body)
    );
  }

}
