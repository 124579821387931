/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { RsaDeviceGroupedResponse } from '../../models/rsa-device-grouped-response';

export interface GetGroupedRsaDevices$Params {

/**
 * Id of a branch group.
 */
  branch_group_id: string;

/**
 * If true will return also disabled devices.
 */
  include_disabled?: boolean;
}

export function getGroupedRsaDevices(http: HttpClient, rootUrl: string, params: GetGroupedRsaDevices$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDeviceGroupedResponse>> {
  const rb = new RequestBuilder(rootUrl, getGroupedRsaDevices.PATH, 'get');
  if (params) {
    rb.path('branch_group_id', params.branch_group_id, {"style":"simple","explode":false});
    rb.query('include_disabled', params.include_disabled, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RsaDeviceGroupedResponse>;
    })
  );
}

getGroupedRsaDevices.PATH = '/api/v1/rsa/devices/branch_groups/{branch_group_id}';
