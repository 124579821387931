/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AreaListResponse } from '../models/area-list-response';
import { AreaResponse } from '../models/area-response';
import { AreaWaitingTimeListResponse } from '../models/area-waiting-time-list-response';
import { AreaWaitingTimeResponse } from '../models/area-waiting-time-response';
import { createArea } from '../fn/areas/create-area';
import { CreateArea$Params } from '../fn/areas/create-area';
import { deleteArea } from '../fn/areas/delete-area';
import { DeleteArea$Params } from '../fn/areas/delete-area';
import { getAllAreas } from '../fn/areas/get-all-areas';
import { GetAllAreas$Params } from '../fn/areas/get-all-areas';
import { getAllAreasWaitingTime } from '../fn/areas/get-all-areas-waiting-time';
import { GetAllAreasWaitingTime$Params } from '../fn/areas/get-all-areas-waiting-time';
import { getAreaById } from '../fn/areas/get-area-by-id';
import { GetAreaById$Params } from '../fn/areas/get-area-by-id';
import { patchAreaWaitingTime } from '../fn/areas/patch-area-waiting-time';
import { PatchAreaWaitingTime$Params } from '../fn/areas/patch-area-waiting-time';
import { updateArea } from '../fn/areas/update-area';
import { UpdateArea$Params } from '../fn/areas/update-area';

@Injectable({ providedIn: 'root' })
export class AreasService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllAreas()` */
  static readonly GetAllAreasPath = '/api/v1/areas';

  /**
   * Get all areas.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAreas$Response(params?: GetAllAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaListResponse>> {
    return getAllAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all areas.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAreas(params?: GetAllAreas$Params, context?: HttpContext): Observable<AreaListResponse> {
    return this.getAllAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaListResponse>): AreaListResponse => r.body)
    );
  }

  /** Path part for operation `createArea()` */
  static readonly CreateAreaPath = '/api/v1/areas';

  /**
   * Create area.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArea()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArea$Response(params: CreateArea$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaResponse>> {
    return createArea(this.http, this.rootUrl, params, context);
  }

  /**
   * Create area.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createArea$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArea(params: CreateArea$Params, context?: HttpContext): Observable<AreaResponse> {
    return this.createArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaResponse>): AreaResponse => r.body)
    );
  }

  /** Path part for operation `getAllAreasWaitingTime()` */
  static readonly GetAllAreasWaitingTimePath = '/api/v1/areas/waiting_time';

  /**
   * get all areas waiting time.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAreasWaitingTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAreasWaitingTime$Response(params?: GetAllAreasWaitingTime$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaWaitingTimeListResponse>> {
    return getAllAreasWaitingTime(this.http, this.rootUrl, params, context);
  }

  /**
   * get all areas waiting time.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAreasWaitingTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAreasWaitingTime(params?: GetAllAreasWaitingTime$Params, context?: HttpContext): Observable<AreaWaitingTimeListResponse> {
    return this.getAllAreasWaitingTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaWaitingTimeListResponse>): AreaWaitingTimeListResponse => r.body)
    );
  }

  /** Path part for operation `getAreaById()` */
  static readonly GetAreaByIdPath = '/api/v1/areas/{id}';

  /**
   * Get area by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAreaById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAreaById$Response(params: GetAreaById$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaResponse>> {
    return getAreaById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get area by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAreaById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAreaById(params: GetAreaById$Params, context?: HttpContext): Observable<AreaResponse> {
    return this.getAreaById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaResponse>): AreaResponse => r.body)
    );
  }

  /** Path part for operation `updateArea()` */
  static readonly UpdateAreaPath = '/api/v1/areas/{id}';

  /**
   * Update area.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArea()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArea$Response(params: UpdateArea$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaResponse>> {
    return updateArea(this.http, this.rootUrl, params, context);
  }

  /**
   * Update area.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArea$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArea(params: UpdateArea$Params, context?: HttpContext): Observable<AreaResponse> {
    return this.updateArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaResponse>): AreaResponse => r.body)
    );
  }

  /** Path part for operation `deleteArea()` */
  static readonly DeleteAreaPath = '/api/v1/areas/{id}';

  /**
   * delete area.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArea$Response(params: DeleteArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteArea(this.http, this.rootUrl, params, context);
  }

  /**
   * delete area.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArea(params: DeleteArea$Params, context?: HttpContext): Observable<void> {
    return this.deleteArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchAreaWaitingTime()` */
  static readonly PatchAreaWaitingTimePath = '/api/v1/areas/{id}/waiting_time';

  /**
   * patch waiting time.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAreaWaitingTime()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAreaWaitingTime$Response(params: PatchAreaWaitingTime$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaWaitingTimeResponse>> {
    return patchAreaWaitingTime(this.http, this.rootUrl, params, context);
  }

  /**
   * patch waiting time.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchAreaWaitingTime$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAreaWaitingTime(params: PatchAreaWaitingTime$Params, context?: HttpContext): Observable<AreaWaitingTimeResponse> {
    return this.patchAreaWaitingTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<AreaWaitingTimeResponse>): AreaWaitingTimeResponse => r.body)
    );
  }

}
