/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getUserInvites } from '../fn/user-invites/get-user-invites';
import { GetUserInvites$Params } from '../fn/user-invites/get-user-invites';
import { updateUserInvite } from '../fn/user-invites/update-user-invite';
import { UpdateUserInvite$Params } from '../fn/user-invites/update-user-invite';
import { UserInviteActionResponse } from '../models/user-invite-action-response';
import { UserInviteListResponse } from '../models/user-invite-list-response';

@Injectable({ providedIn: 'root' })
export class UserInvitesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUserInvites()` */
  static readonly GetUserInvitesPath = '/api/v1/user_invites';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInvites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvites$Response(params?: GetUserInvites$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInviteListResponse>> {
    return getUserInvites(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserInvites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvites(params?: GetUserInvites$Params, context?: HttpContext): Observable<UserInviteListResponse> {
    return this.getUserInvites$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInviteListResponse>): UserInviteListResponse => r.body)
    );
  }

  /** Path part for operation `updateUserInvite()` */
  static readonly UpdateUserInvitePath = '/api/v1/user_invites/{id}';

  /**
   * Update a resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserInvite()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserInvite$Response(params: UpdateUserInvite$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInviteActionResponse>> {
    return updateUserInvite(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUserInvite$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserInvite(params: UpdateUserInvite$Params, context?: HttpContext): Observable<UserInviteActionResponse> {
    return this.updateUserInvite$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInviteActionResponse>): UserInviteActionResponse => r.body)
    );
  }

}
