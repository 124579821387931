import { User, Role } from '@dispo-shared/open-api/models';

export enum UsersType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface UrlParams {
  groupBranch: string;
  singleBranch: string;
  singleBranchSearch: string;
  usersSearch: string;
  usersType: string;
}
export interface UserBranchAccess extends User {
  branchAccessRoles: Role[];
  singleBranchAccessRoles: Role[];
}

export interface UsersSearchQueryObj {
  [key: string]: string;
}
export interface BranchesSearchQueryObj {
  [key: string]: string;
}

export type InternalUsersTabValue = { [key: string]: UserBranchAccess[] | null };
export type ExternalUsersTabValue = {
  [key: string]: { [key: string]: { [key: string]: UserBranchAccess[] | null } };
};
export type UsersTabValue = InternalUsersTabValue | ExternalUsersTabValue;
