/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface TriggerSync$Params {

/**
 * tenant shortcode
 */
  tenant_short_code: string;

/**
 * the sync you wish to trigger
 */
  job: 'BRANCHES' | 'LEA_DRIVERS';
}

export function triggerSync(http: HttpClient, rootUrl: string, params: TriggerSync$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, triggerSync.PATH, 'post');
  if (params) {
    rb.path('tenant_short_code', params.tenant_short_code, {"style":"simple","explode":false});
    rb.query('job', params.job, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

triggerSync.PATH = '/api/v1/{tenant_short_code}/sync';
