import { Injectable, inject } from '@angular/core';
import { DirectionsService } from '@dispo-shared/ors-api/services/directions.service';
import { GetGeoJsonRouteUsingPost$Json$Params } from '@dispo-shared/ors-api/fn/directions/get-geo-json-route-using-post-json';
import { GeoJsonRouteResponse } from '@dispo-shared/ors-api/models/geo-json-route-response';

export interface GeoRouteWaypoint {
  lon: number;
  lat: number;
}

@Injectable()
export class GeoRouteService {
  private readonly directionsService: DirectionsService = inject(DirectionsService);

  public getGeoRoute(waypoints: GeoRouteWaypoint[]): Promise<GeoJsonRouteResponse> {
    return new Promise((resolve) => {
      // Create coordinates array from waypoints
      const coordinates = waypoints.map((waypoint) => [waypoint.lon, waypoint.lat]);

      // Create parameters for the request
      const directionParams: GetGeoJsonRouteUsingPost$Json$Params = {
        profile: 'driving-car',
        body: {
          coordinates: coordinates,
        },
      };

      // Send it...
      this.directionsService
        .getGeoJsonRouteUsingPost$Json(directionParams)
        .subscribe((response) => {
          resolve(response);
        });
    });
  }
}
