/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { StoredAddress } from '../../models/stored-address';
import { UpdateStoredAddressRequest } from '../../models/update-stored-address-request';

export interface UpdateAddress$Params {

/**
 * Id of a resource.
 */
  id: string;
      body: UpdateStoredAddressRequest
}

export function updateAddress(http: HttpClient, rootUrl: string, params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddress>> {
  const rb = new RequestBuilder(rootUrl, updateAddress.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StoredAddress>;
    })
  );
}

updateAddress.PATH = '/api/v1/addresses/{id}';
