import { Action } from '@ngrx/store';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '../../interfaces/error.interface';

export enum DeviceSidebarsActionTypes {
  SetManageDialogOpenStatus = '[DeviceSidebars] Set manage dialog open status',
  SetManageDialogRequestStatus = '[DeviceSidebars] Set manage dialog API request status',
  SetAssociationSidebarOpenStatus = '[DeviceSidebars] Set association dialog open status',
  SetAssociationSidebarRequestStatus = '[DeviceSidebars] Set association dialog API request status',
}

export class SetManageDialogOpenStatus implements Action {
  public readonly type = DeviceSidebarsActionTypes.SetManageDialogOpenStatus;
  constructor(public payload: { open: boolean }) {}
}

export class SetManageDialogRequestStatus implements Action {
  public readonly type = DeviceSidebarsActionTypes.SetManageDialogRequestStatus;
  constructor(public payload: { status: Status; error?: ErrorResponse | string }) {}
}

export class SetAssociationSidebarOpenStatus implements Action {
  public readonly type = DeviceSidebarsActionTypes.SetAssociationSidebarOpenStatus;
  constructor(public payload: { open: boolean }) {}
}

export class SetAssociationSidebarRequestStatus implements Action {
  public readonly type = DeviceSidebarsActionTypes.SetAssociationSidebarRequestStatus;
  constructor(public payload: { status: Status; error?: string }) {}
}

export type DeviceSidebarsActions =
  | SetManageDialogOpenStatus
  | SetManageDialogRequestStatus
  | SetAssociationSidebarOpenStatus
  | SetAssociationSidebarRequestStatus;
