/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AuthDeviceRequest } from '../../models/auth-device-request';
import { AuthDeviceResponse } from '../../models/auth-device-response';

export interface AuthDevice$Params {

/**
 * uuid key of the tenant
 */
  'X-Tenant-Key'?: string;
      body: AuthDeviceRequest
}

export function authDevice(http: HttpClient, rootUrl: string, params: AuthDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthDeviceResponse>> {
  const rb = new RequestBuilder(rootUrl, authDevice.PATH, 'post');
  if (params) {
    rb.header('X-Tenant-Key', params['X-Tenant-Key'], {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AuthDeviceResponse>;
    })
  );
}

authDevice.PATH = '/api/v1/auth/device';
