/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createRsaDevice } from '../fn/rsa-devices/create-rsa-device';
import { CreateRsaDevice$Params } from '../fn/rsa-devices/create-rsa-device';
import { deleteRsaDevice } from '../fn/rsa-devices/delete-rsa-device';
import { DeleteRsaDevice$Params } from '../fn/rsa-devices/delete-rsa-device';
import { getGroupedRsaDevices } from '../fn/rsa-devices/get-grouped-rsa-devices';
import { GetGroupedRsaDevices$Params } from '../fn/rsa-devices/get-grouped-rsa-devices';
import { getRsaDevice } from '../fn/rsa-devices/get-rsa-device';
import { GetRsaDevice$Params } from '../fn/rsa-devices/get-rsa-device';
import { getRsaDevices } from '../fn/rsa-devices/get-rsa-devices';
import { GetRsaDevices$Params } from '../fn/rsa-devices/get-rsa-devices';
import { RsaDevice } from '../models/rsa-device';
import { RsaDeviceGroupedResponse } from '../models/rsa-device-grouped-response';
import { RsaDeviceListResponse } from '../models/rsa-device-list-response';
import { updateRsaDevice } from '../fn/rsa-devices/update-rsa-device';
import { UpdateRsaDevice$Params } from '../fn/rsa-devices/update-rsa-device';

@Injectable({ providedIn: 'root' })
export class RsaDevicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRsaDevices()` */
  static readonly GetRsaDevicesPath = '/api/v1/rsa/devices';

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRsaDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaDevices$Response(params?: GetRsaDevices$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDeviceListResponse>> {
    return getRsaDevices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get paginated list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRsaDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaDevices(params?: GetRsaDevices$Params, context?: HttpContext): Observable<RsaDeviceListResponse> {
    return this.getRsaDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<RsaDeviceListResponse>): RsaDeviceListResponse => r.body)
    );
  }

  /** Path part for operation `createRsaDevice()` */
  static readonly CreateRsaDevicePath = '/api/v1/rsa/devices';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRsaDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRsaDevice$Response(params: CreateRsaDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDevice>> {
    return createRsaDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRsaDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRsaDevice(params: CreateRsaDevice$Params, context?: HttpContext): Observable<RsaDevice> {
    return this.createRsaDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<RsaDevice>): RsaDevice => r.body)
    );
  }

  /** Path part for operation `getGroupedRsaDevices()` */
  static readonly GetGroupedRsaDevicesPath = '/api/v1/rsa/devices/branch_groups/{branch_group_id}';

  /**
   * Get list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroupedRsaDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedRsaDevices$Response(params: GetGroupedRsaDevices$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDeviceGroupedResponse>> {
    return getGroupedRsaDevices(this.http, this.rootUrl, params, context);
  }

  /**
   * Get list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGroupedRsaDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGroupedRsaDevices(params: GetGroupedRsaDevices$Params, context?: HttpContext): Observable<RsaDeviceGroupedResponse> {
    return this.getGroupedRsaDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<RsaDeviceGroupedResponse>): RsaDeviceGroupedResponse => r.body)
    );
  }

  /** Path part for operation `getRsaDevice()` */
  static readonly GetRsaDevicePath = '/api/v1/rsa/devices/{id}';

  /**
   * Get existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRsaDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaDevice$Response(params: GetRsaDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDevice>> {
    return getRsaDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Get existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRsaDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRsaDevice(params: GetRsaDevice$Params, context?: HttpContext): Observable<RsaDevice> {
    return this.getRsaDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<RsaDevice>): RsaDevice => r.body)
    );
  }

  /** Path part for operation `updateRsaDevice()` */
  static readonly UpdateRsaDevicePath = '/api/v1/rsa/devices/{id}';

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRsaDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRsaDevice$Response(params: UpdateRsaDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<RsaDevice>> {
    return updateRsaDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRsaDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRsaDevice(params: UpdateRsaDevice$Params, context?: HttpContext): Observable<RsaDevice> {
    return this.updateRsaDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<RsaDevice>): RsaDevice => r.body)
    );
  }

  /** Path part for operation `deleteRsaDevice()` */
  static readonly DeleteRsaDevicePath = '/api/v1/rsa/devices/{id}';

  /**
   * Delete existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRsaDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRsaDevice$Response(params: DeleteRsaDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteRsaDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRsaDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRsaDevice(params: DeleteRsaDevice$Params, context?: HttpContext): Observable<void> {
    return this.deleteRsaDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
