import { Injectable, Signal, WritableSignal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GrantsService {
  private _grants: WritableSignal<string[]> = signal([]);
  public grants: Signal<string[]> = this._grants.asReadonly();

  setGrants(token: string): void {
    const tokenSplit = token.split('.')[1];
    if (tokenSplit) {
      const claims = atob(tokenSplit);
      const claimsJson = JSON.parse(claims);
      const principal = atob(claimsJson.principal);
      const grants = JSON.parse(principal).grants;
      this._grants.set(grants);
    }
  }
}
