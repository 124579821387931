/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { StoredAddressListResponse } from '../../models/stored-address-list-response';

export interface GetDefaultAddresses$Params {

/**
 * the type of address that you want in the response
 */
  type?: string;

/**
 * ID of the stored_address_type the requested addresses belong ot
 */
  stored_address_type_id?: string;
}

export function getDefaultAddresses(http: HttpClient, rootUrl: string, params?: GetDefaultAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddressListResponse>> {
  const rb = new RequestBuilder(rootUrl, getDefaultAddresses.PATH, 'get');
  if (params) {
    rb.query('type', params.type, {"style":"form","explode":true});
    rb.query('stored_address_type_id', params.stored_address_type_id, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StoredAddressListResponse>;
    })
  );
}

getDefaultAddresses.PATH = '/api/v1/addresses';
