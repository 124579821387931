/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associateCaseWithBranch } from '../fn/branches/associate-case-with-branch';
import { AssociateCaseWithBranch$Params } from '../fn/branches/associate-case-with-branch';
import { Branch } from '../models/branch';
import { BranchGroup } from '../models/branch-group';
import { BranchGroupListResponse } from '../models/branch-group-list-response';
import { BranchListResponse } from '../models/branch-list-response';
import { createBranch } from '../fn/branches/create-branch';
import { CreateBranch$Params } from '../fn/branches/create-branch';
import { createBranchGroup } from '../fn/branches/create-branch-group';
import { CreateBranchGroup$Params } from '../fn/branches/create-branch-group';
import { getBranchById } from '../fn/branches/get-branch-by-id';
import { GetBranchById$Params } from '../fn/branches/get-branch-by-id';
import { getBranches } from '../fn/branches/get-branches';
import { GetBranches$Params } from '../fn/branches/get-branches';
import { getBranchGroups } from '../fn/branches/get-branch-groups';
import { GetBranchGroups$Params } from '../fn/branches/get-branch-groups';
import { Tour } from '../models/tour';
import { updateBranch } from '../fn/branches/update-branch';
import { UpdateBranch$Params } from '../fn/branches/update-branch';
import { updateBranchGroup } from '../fn/branches/update-branch-group';
import { UpdateBranchGroup$Params } from '../fn/branches/update-branch-group';

@Injectable({ providedIn: 'root' })
export class BranchesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getBranches()` */
  static readonly GetBranchesPath = '/api/v1/branches';

  /**
   * Get branches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranches$Response(params?: GetBranches$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchListResponse>> {
    return getBranches(this.http, this.rootUrl, params, context);
  }

  /**
   * Get branches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranches(params?: GetBranches$Params, context?: HttpContext): Observable<BranchListResponse> {
    return this.getBranches$Response(params, context).pipe(
      map((r: StrictHttpResponse<BranchListResponse>): BranchListResponse => r.body)
    );
  }

  /** Path part for operation `createBranch()` */
  static readonly CreateBranchPath = '/api/v1/branches';

  /**
   * Post branches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBranch$Response(params: CreateBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchGroup>> {
    return createBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Post branches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBranch(params: CreateBranch$Params, context?: HttpContext): Observable<BranchGroup> {
    return this.createBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<BranchGroup>): BranchGroup => r.body)
    );
  }

  /** Path part for operation `associateCaseWithBranch()` */
  static readonly AssociateCaseWithBranchPath = '/api/v1/branches/associated_cases';

  /**
   * Associate case with branch.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateCaseWithBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateCaseWithBranch$Response(params: AssociateCaseWithBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return associateCaseWithBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Associate case with branch.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateCaseWithBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateCaseWithBranch(params: AssociateCaseWithBranch$Params, context?: HttpContext): Observable<Tour> {
    return this.associateCaseWithBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `getBranchGroups()` */
  static readonly GetBranchGroupsPath = '/api/v1/branches/groups';

  /**
   * Get groups of branches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBranchGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranchGroups$Response(params?: GetBranchGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchGroupListResponse>> {
    return getBranchGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get groups of branches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBranchGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranchGroups(params?: GetBranchGroups$Params, context?: HttpContext): Observable<BranchGroupListResponse> {
    return this.getBranchGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<BranchGroupListResponse>): BranchGroupListResponse => r.body)
    );
  }

  /** Path part for operation `createBranchGroup()` */
  static readonly CreateBranchGroupPath = '/api/v1/branches/groups';

  /**
   * Post group of branches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBranchGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBranchGroup$Response(params: CreateBranchGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchGroup>> {
    return createBranchGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Post group of branches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBranchGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBranchGroup(params: CreateBranchGroup$Params, context?: HttpContext): Observable<BranchGroup> {
    return this.createBranchGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<BranchGroup>): BranchGroup => r.body)
    );
  }

  /** Path part for operation `updateBranchGroup()` */
  static readonly UpdateBranchGroupPath = '/api/v1/branches/groups/{id}';

  /**
   * Update group of branches.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBranchGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBranchGroup$Response(params: UpdateBranchGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchGroup>> {
    return updateBranchGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Update group of branches.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBranchGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBranchGroup(params: UpdateBranchGroup$Params, context?: HttpContext): Observable<BranchGroup> {
    return this.updateBranchGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<BranchGroup>): BranchGroup => r.body)
    );
  }

  /** Path part for operation `getBranchById()` */
  static readonly GetBranchByIdPath = '/api/v1/branches/{id}';

  /**
   * Get branch by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBranchById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranchById$Response(params: GetBranchById$Params, context?: HttpContext): Observable<StrictHttpResponse<Branch>> {
    return getBranchById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get branch by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBranchById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBranchById(params: GetBranchById$Params, context?: HttpContext): Observable<Branch> {
    return this.getBranchById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Branch>): Branch => r.body)
    );
  }

  /** Path part for operation `updateBranch()` */
  static readonly UpdateBranchPath = '/api/v1/branches/{id}';

  /**
   * Update branch.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBranch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBranch$Response(params: UpdateBranch$Params, context?: HttpContext): Observable<StrictHttpResponse<Branch>> {
    return updateBranch(this.http, this.rootUrl, params, context);
  }

  /**
   * Update branch.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBranch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBranch(params: UpdateBranch$Params, context?: HttpContext): Observable<Branch> {
    return this.updateBranch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Branch>): Branch => r.body)
    );
  }

}
