/* eslint-disable @typescript-eslint/ban-ts-comment */
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { RolesService, SkillsService, UsersService } from '@dispo-shared/open-api/services';
import { Branch, BranchGroup, Role, Skill, User } from '@dispo-shared/open-api/models';
import { GetUsers$Params } from '@dispo-shared/open-api/fn/users/get-users';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  BranchesSearchQueryObj,
  ExternalUsersTabValue,
  InternalUsersTabValue,
  UrlParams,
  UserBranchAccess,
  UsersSearchQueryObj,
  UsersTabValue,
  UsersType,
} from '../shared/users/models/users.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersStoreService {
  private readonly usersService: UsersService = inject(UsersService);
  private readonly rolesService: RolesService = inject(RolesService);
  private readonly skillsService: SkillsService = inject(SkillsService);
  private readonly location: Location = inject(Location);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  private _users: WritableSignal<UsersTabValue> = signal({});
  private _roles: WritableSignal<{ [key: string]: Role[] }> = signal({});

  private _usersTypeTabs: WritableSignal<UsersType[]> = signal([]);
  private _activeUsersTypeTabIndex: WritableSignal<number> = signal(0);
  private _activeBranchCollectionTabIndex: WritableSignal<number> = signal(0);
  private _activeBranchSingleIndex: WritableSignal<{ [key: number]: number }> = signal({ 0: 0 });
  private _isSingleBranchType: WritableSignal<boolean> = signal(false);
  private _loading: WritableSignal<boolean> = signal(false);
  private _branches: WritableSignal<Branch[]> = signal([]);
  private _urlParams: WritableSignal<UrlParams | null> = signal(null);
  private _usersSearchQuery: WritableSignal<UsersSearchQueryObj> = signal({});
  private _branchesSearchQuery: WritableSignal<BranchesSearchQueryObj> = signal({});
  private _skills: WritableSignal<Skill[]> = signal([]);
  private _connected: WritableSignal<boolean | null> = signal(null);

  public users: Signal<UsersTabValue> = this._users.asReadonly();
  public roles: Signal<{ [key: string]: Role[] }> = this._roles.asReadonly();
  public usersTypeTabs: Signal<UsersType[]> = this._usersTypeTabs.asReadonly();
  public activeUsersTypeTabIndex: Signal<number> = this._activeUsersTypeTabIndex.asReadonly();
  public activeBranchCollectionTabIndex: Signal<number> =
    this._activeBranchCollectionTabIndex.asReadonly();
  public activeBranchSingleIndex: Signal<{ [key: number]: number }> =
    this._activeBranchSingleIndex.asReadonly();
  public loading: Signal<boolean> = this._loading.asReadonly();
  public branches: Signal<Branch[]> = this._branches.asReadonly();
  public isSingleBranchType: Signal<boolean> = this._isSingleBranchType.asReadonly();
  public skills: Signal<Skill[]> = this._skills.asReadonly();
  public connected: Signal<boolean | null> = this._connected.asReadonly();
  public usersSearchQuery: Signal<UsersSearchQueryObj> = this._usersSearchQuery.asReadonly();
  public branchesSearchQuery: Signal<BranchesSearchQueryObj> =
    this._branchesSearchQuery.asReadonly();

  // computed signals
  public selectedBranch: Signal<Branch | undefined> = computed(() => {
    return this.branches()?.[
      this.activeBranchSingleIndex()[this.activeBranchCollectionTabIndex()] ?? -1
    ];
  });
  public activeUsersTypeTab: Signal<UsersType | undefined> = computed(
    () => this.usersTypeTabs()?.[this.activeUsersTypeTabIndex()]
  );

  public fetchInitialData(
    branchGroups: BranchGroup[],
    singleBranchGroups: BranchGroup[],
    hasBranchCollectionTypeGroup: boolean,
    isSingleBranchType: boolean
  ): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.constructUserTypeTabs(hasBranchCollectionTypeGroup, isSingleBranchType);
      this.constructUserObj(branchGroups);
      this.constructRolesObj();
      this.constructUsersSearchQueryObj(branchGroups);
      if (!isSingleBranchType) this.constructBranchesSearchQueryObj(branchGroups);

      // get active selected user type tab index
      let activeTabIndex = this.usersTypeTabs()?.findIndex((tab) => tab === params['usersType']);
      if (activeTabIndex < 0) {
        activeTabIndex = this.activeUsersTypeTabIndex();
      }

      // get active selected branch group index
      let activeBranchIndex = 0;
      const externalUsersTab = this.users() as ExternalUsersTabValue;
      if (!isSingleBranchType) {
        if (externalUsersTab[UsersType.EXTERNAL]) {
          activeBranchIndex = Object.keys(externalUsersTab[UsersType.EXTERNAL]).findIndex(
            (key) => key === params['groupBranch']
          );

          if (activeBranchIndex < 0) {
            activeBranchIndex = this.activeBranchCollectionTabIndex();
          }
        }
      }

      // get active selected single branch index
      let singleBranchIndex = Object.keys(
        (this.users() as ExternalUsersTabValue)[UsersType.EXTERNAL as keyof UsersTabValue]?.[
          params['groupBranch']
        ] ?? {}
      )?.findIndex((key) => key === params['singleBranch']);

      if (singleBranchIndex < 0) {
        const activeBranchSingleIndex =
          this.activeBranchSingleIndex()[this.activeBranchCollectionTabIndex()];

        if (activeBranchSingleIndex !== undefined && activeBranchSingleIndex > -1) {
          singleBranchIndex = activeBranchSingleIndex;
        }
      }

      this.setActiveUsersTypeIndex(activeTabIndex < 0 ? 0 : activeTabIndex);
      this.setActiveBranchGroupsIndex(activeBranchIndex);

      this.constructActiveSingleBranchObj(branchGroups, singleBranchIndex);

      const usersType = this.activeUsersTypeTab();
      if (usersType === UsersType.EXTERNAL) {
        const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
        if (branchGroup) {
          this.setBranches(branchGroup.branches);

          // set users table search query for external users type
          const paramSingleBranchIndex = this.branches()?.findIndex(
            (branch) => branch.id === params['singleBranch']
          );
          if (paramSingleBranchIndex > -1 && params['usersSearch']) {
            const singleBranch = this.getSingleBranchGroupId(
              this.selectedBranch() ?? ({} as Branch),
              branchGroup.branches
            );

            if (singleBranch) {
              this.updateUsersSearchQuery(singleBranch, params['usersSearch']);
            }
          }

          // set branches table search query for external users type
          const paramBranchGroupIndex = branchGroups?.findIndex(
            (branch) => branch.id === params['groupBranch']
          );
          if (paramBranchGroupIndex > -1 && params['singleBranchSearch']) {
            const branchGroupId = this.getGroupBranchId(branchGroup, branchGroups);
            if (branchGroup) {
              this.updateBranchesSearchQuery(branchGroupId, params['singleBranchSearch']);
            }
          }
        }
      } else if (params['usersSearch']) {
        // set users table search query for internal users type
        this.updateUsersSearchQuery(this.activeUsersTypeTab(), params['usersSearch']);
      }
      this.fetchUsers(this.activeUsersTypeTab(), branchGroups, singleBranchGroups);
      this.fetchRoles(
        usersType === UsersType.INTERNAL ? 'ALL' : 'BRANCH_SINGLE',
        this.activeUsersTypeTab()
      );
      if (isSingleBranchType) {
        this.fetchSkills();
      }
      this.writeAsURLParams(branchGroups);
    });
  }

  public onActiveUsersTypeIndexChange(
    event: number,
    branchGroups: BranchGroup[],
    singleBranchGroups: BranchGroup[]
  ): void {
    this.setActiveUsersTypeIndex(event);
    if (this.activeUsersTypeTab() === UsersType.INTERNAL) {
      if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
        this.fetchUsers(UsersType.INTERNAL, branchGroups, singleBranchGroups);
        this.fetchRoles('ALL', this.activeUsersTypeTab());
      }
      this.writeAsURLParams(branchGroups);
    } else if (this.activeUsersTypeTab() === UsersType.EXTERNAL) {
      const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];

      if (branchGroup) {
        this.setBranches(branchGroup.branches);
      }

      if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
        this.fetchUsers(UsersType.EXTERNAL, branchGroups, singleBranchGroups);
        this.fetchRoles('BRANCH_SINGLE', this.activeUsersTypeTab());
      }
      this.writeAsURLParams(branchGroups);
    } else if (this.activeUsersTypeTab() === UsersType.ACTIVE) {
      if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
        this.fetchUsers(UsersType.ACTIVE, branchGroups, singleBranchGroups);
        this.fetchRoles('ALL', this.activeUsersTypeTab());
      }
    } else if (this.activeUsersTypeTab() === UsersType.INACTIVE) {
      if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
        this.fetchUsers(UsersType.INACTIVE, branchGroups, singleBranchGroups);
        this.fetchRoles('ALL', this.activeUsersTypeTab());
      }
    }
  }

  public onActiveBranchTypeIndexChange(
    event: number,
    branchGroups: BranchGroup[],
    singleBranchGroups: BranchGroup[]
  ): void {
    this.setActiveBranchGroupsIndex(event);
    const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      this.setBranches(branchGroup.branches);
    }
    if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
      this.fetchUsers(UsersType.EXTERNAL, branchGroups, singleBranchGroups);
    }
    this.writeAsURLParams(branchGroups);
  }

  public onSelectedSingleBranchChange(
    event: number,
    branchGroups: BranchGroup[],
    singleBranchGroups: BranchGroup[]
  ): void {
    this.updateSingleBranchIndex(this.activeBranchCollectionTabIndex(), event);
    if (!this.getCurrentUsers(branchGroups, singleBranchGroups)) {
      this.fetchUsers(UsersType.EXTERNAL, branchGroups, singleBranchGroups);
    }
    this.writeAsURLParams(branchGroups);
  }

  public onUsersSearchQueryChanged(event: string, branchGroups: BranchGroup[]): void {
    const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const singleBranch = this.getSingleBranchGroupId(
        this.selectedBranch() ?? ({} as Branch),
        branchGroup.branches
      );

      const noExternalUserType = [UsersType.INTERNAL, UsersType.INACTIVE, UsersType.ACTIVE];
      const includesActiveUserTypeTab = noExternalUserType.includes(
        this.activeUsersTypeTab() ?? UsersType.INTERNAL
      );

      this.updateUsersSearchQuery(
        includesActiveUserTypeTab ? this.activeUsersTypeTab() : singleBranch,
        event
      );

      this.writeAsURLParams(branchGroups);
    }
  }

  public onBranchesSearchQueryChanged(event: string, branchGroups: BranchGroup[]): void {
    const branchgroupActiveBranchCollectionTabIndex =
      branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchgroupActiveBranchCollectionTabIndex) {
      const branchGroup = this.getGroupBranchId(
        branchgroupActiveBranchCollectionTabIndex,
        branchGroups
      );

      if (branchGroup) {
        this.updateBranchesSearchQuery(branchGroup, event);
      }

      this.writeAsURLParams(branchGroups);
    }
  }

  public fetchUsers(
    usersType: UsersType | undefined,
    groupBranches: BranchGroup[],
    singleGroupBranches: BranchGroup[]
  ): void {
    const branchGroup = groupBranches[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const activeBranchGroupId = this.getGroupBranchId(branchGroup, groupBranches);
      const singleBranchGroupId = this.getSingleBranchGroupId(
        this.selectedBranch() ?? ({} as Branch),
        singleGroupBranches
      );
      this.setLoadingState(true);
      const requestPayload: GetUsers$Params = {
        size: 1000,
        sort: ['createdAt'],
        ...(usersType &&
          [UsersType.INTERNAL, UsersType.EXTERNAL].includes(usersType) && {
            associated_roles_branch_access_scope:
              usersType === UsersType.INTERNAL ? 'ALL' : 'BRANCH_SINGLE',
          }),
        ...(usersType === UsersType.EXTERNAL && {
          branch_group_id: singleBranchGroupId,
        }),
      };

      this.usersService.getUsers(requestPayload).subscribe({
        next: (response) => {
          if (response && response.users) {
            let users = this.transformToUsersWithGroupedRolesBasedByBranchAccess(response.users);

            if (usersType === UsersType.ACTIVE) {
              users = users.filter((user) => user.active_states && user?.active_states[0]?.active);
            } else if (usersType === UsersType.INACTIVE) {
              users = users.filter((user) => user.active_states && !user?.active_states[0]?.active);
            }
            this.updateUsers(users, activeBranchGroupId, singleBranchGroupId);
            // Iterate over all users and check if they have a roll with name 'Disponent'
          } else {
            this.updateUsers([], activeBranchGroupId, singleBranchGroupId);
          }
          this.setLoadingState(false);
        },
        error: (error) => {
          console.error(`[UserService][getAllUser]`, error);
          this.setLoadingState(false);
        },
      });
    }
  }

  public getSingleBranchGroupId(singleBranch: Branch, branchSingleGroups: Branch[]): string {
    const singleBranchGroupId = branchSingleGroups?.find(
      (branch) => branch?.name === singleBranch?.name
    )?.id;
    return singleBranchGroupId ?? '';
  }

  public getGroupBranchId(groupBranch: Branch, branchGroups: BranchGroup[]): string {
    return branchGroups?.find((branch) => branch?.name === groupBranch?.name)?.id ?? '';
  }

  public onUserCreationUpdateUsers(
    user: User,
    branchGroups: BranchGroup[],
    singleGroupBranches: BranchGroup[]
  ): void {
    const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const activeBranchGroupId = this.getGroupBranchId(branchGroup, branchGroups);
      const singleBranchGroupId = this.getSingleBranchGroupId(
        this.selectedBranch() ?? ({} as Branch),
        singleGroupBranches
      );
      const updatedUserWithRoles = this.transformToUserWithGroupedRolesBasedByBranchAccess(user);
      if (activeBranchGroupId) {
        if (this.activeUsersTypeTab() !== undefined) {
          this._users.update(
            (users) =>
              ({
                ...users,
                ...([UsersType.INTERNAL, UsersType.ACTIVE].includes(
                  this.activeUsersTypeTab() as UsersType
                ) &&
                  ({
                    [this.activeUsersTypeTab() as string]: [
                      ...(users[this.activeUsersTypeTab() as UsersType] as UserBranchAccess[]),
                      updatedUserWithRoles,
                    ],
                  } as InternalUsersTabValue)),
                ...([UsersType.INACTIVE].includes(this.activeUsersTypeTab() as UsersType) &&
                  users[UsersType.ACTIVE] &&
                  ({
                    [UsersType.ACTIVE]: [
                      ...(users[UsersType.ACTIVE] as UserBranchAccess[]),
                      updatedUserWithRoles,
                    ],
                  } as InternalUsersTabValue)),
                ...(this.activeUsersTypeTab() === UsersType.EXTERNAL &&
                  ({
                    [UsersType.EXTERNAL]: {
                      ...(users as ExternalUsersTabValue)[UsersType.EXTERNAL],
                      [activeBranchGroupId]: {
                        ...(users as ExternalUsersTabValue)[UsersType.EXTERNAL]?.[
                          activeBranchGroupId
                        ],
                        [singleBranchGroupId]: [
                          ...((users as ExternalUsersTabValue)[UsersType.EXTERNAL]?.[
                            activeBranchGroupId
                          ]?.[singleBranchGroupId] ?? []),
                          updatedUserWithRoles,
                        ],
                      },
                    },
                  } as ExternalUsersTabValue)),
              }) as UsersTabValue
          );
        }
      }
    }
  }

  public onUserEditUpdateUsers(
    user: User,
    branchGroups: BranchGroup[],
    singleGroupBranches: BranchGroup[]
  ): void {
    const updatedUserWithRoles = this.transformToUserWithGroupedRolesBasedByBranchAccess(user);
    this._users.update((users) =>
      this.usersTypeTabs()
        .map((tab) => ({
          ...([UsersType.INTERNAL, UsersType.ACTIVE, UsersType.INACTIVE].includes(tab) && {
            [tab]: users?.[tab]
              ? (users?.[tab] as UserBranchAccess[]).map((u) => {
                  if (u.id === updatedUserWithRoles.id) {
                    return updatedUserWithRoles;
                  }
                  return u;
                })
              : null,
          }),
          ...(tab === UsersType.EXTERNAL && {
            [tab]: branchGroups
              .map((branch) => ({
                [branch.id as string]: singleGroupBranches
                  ?.map((singleBranch) => ({
                    [singleBranch?.id as string]: (users as ExternalUsersTabValue)?.[tab]?.[
                      branch?.id as string
                    ]?.[singleBranch?.id as string]
                      ? (
                          (users as ExternalUsersTabValue)?.[tab]?.[branch?.id as string]?.[
                            singleBranch?.id as string
                          ] as UserBranchAccess[]
                        ).map((u) => {
                          if (u.id === updatedUserWithRoles.id) {
                            return updatedUserWithRoles;
                          }
                          return u;
                        })
                      : null,
                  }))
                  .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
              }))
              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          }),
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    );
  }

  public onUserDeleteUpdateUsers(
    userId: string,
    branchGroups: BranchGroup[],
    singleGroupBranches: BranchGroup[]
  ): void {
    const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const activeBranchGroupId = this.getGroupBranchId(branchGroup, branchGroups);
      const singleBranchGroupId = this.getSingleBranchGroupId(
        this.selectedBranch() ?? ({} as Branch),
        singleGroupBranches
      );
      if (this.activeUsersTypeTab() !== undefined)
        this._users.update(
          (users) =>
            ({
              ...users,
              ...([UsersType.INTERNAL, UsersType.ACTIVE, UsersType.INACTIVE].includes(
                this.activeUsersTypeTab() as UsersType
              ) &&
                ({
                  [this.activeUsersTypeTab() as UsersType]: [
                    ...(users[this.activeUsersTypeTab() as UsersType] as UserBranchAccess[]).filter(
                      (u) => u.id !== userId
                    ),
                  ],
                } as InternalUsersTabValue)),
              ...(this.activeUsersTypeTab() === UsersType.EXTERNAL &&
                ({
                  [UsersType.EXTERNAL]: {
                    ...(users as ExternalUsersTabValue)[UsersType.EXTERNAL],
                    [activeBranchGroupId]: {
                      ...(users as ExternalUsersTabValue)[UsersType.EXTERNAL]?.[
                        activeBranchGroupId
                      ],
                      [singleBranchGroupId]: [
                        ...(
                          (users as ExternalUsersTabValue)[UsersType.EXTERNAL]?.[
                            activeBranchGroupId
                          ]?.[singleBranchGroupId] ?? []
                        ).filter((u) => u.id !== userId),
                      ],
                    },
                  },
                } as ExternalUsersTabValue)),
            }) as UsersTabValue
        );
    }
  }

  public onUserDeactivateUpdateUsers(user: User): void {
    const updatedUserWithRoles = this.transformToUserWithGroupedRolesBasedByBranchAccess(user);
    this._users.update(
      (users) =>
        ({
          ...users,
          ...(users[UsersType.ACTIVE] && {
            [UsersType.ACTIVE]: [
              ...(users[UsersType.ACTIVE] as UserBranchAccess[]).filter(
                (u) => u.id !== updatedUserWithRoles.id
              ),
            ],
          }),
          ...(users[UsersType.INACTIVE] && {
            [UsersType.INACTIVE]: [
              ...(users[UsersType.INACTIVE] as UserBranchAccess[]),
              updatedUserWithRoles,
            ],
          }),
        }) as UsersTabValue
    );
  }

  public onUserActivateUpdateUsers(user: User): void {
    const updatedUserWithRoles = this.transformToUserWithGroupedRolesBasedByBranchAccess(user);
    this._users.update(
      (users) =>
        ({
          ...users,
          ...(users[UsersType.INACTIVE] && {
            [UsersType.INACTIVE]: [
              ...(users[UsersType.INACTIVE] as UserBranchAccess[]).filter(
                (u) => u.id !== updatedUserWithRoles.id
              ),
            ],
          }),
          ...(users[UsersType.ACTIVE] && {
            [UsersType.ACTIVE]: [
              ...(users[UsersType.ACTIVE] as UserBranchAccess[]),
              updatedUserWithRoles,
            ],
          }),
        }) as UsersTabValue
    );
  }

  public unblockUser(
    user: User,
    branchGroups: BranchGroup[],
    singleGroupBranches: BranchGroup[]
  ): void {
    this.usersService.unblockUser({ id: user?.id as string }).subscribe({
      next: () => {
        if (user.lock_version) {
          this.onUserEditUpdateUsers(
            { ...user, lock_version: user.lock_version + 1 },
            branchGroups,
            singleGroupBranches
          );
        }
      },
      error: (error) => {
        console.error(`[UserService][unblockUser]`, error);
      },
    });
  }

  public activateUserToggle(user: User, active: boolean): void {
    if (user.id && user.lock_version && user.active_states?.[0]?.branch_group_id) {
      this.usersService
        .updateUserActiveState({
          id: user.id,
          body: {
            active: active,
            lock_version: user?.lock_version,
            branch_group_id: user.active_states?.[0]?.branch_group_id,
          },
        })
        .subscribe({
          next: (res) => {
            if (!res?.active_states?.[0]?.active) {
              this.onUserDeactivateUpdateUsers(res);
            } else {
              this.onUserActivateUpdateUsers(res);
            }
          },
          error: (error) => {
            console.error(`[UserService][unblockUser]`, error);
          },
        });
    }
  }

  public setConnected(connected: boolean): void {
    this._connected.set(connected);
  }

  private transformToUsersWithGroupedRolesBasedByBranchAccess(users: User[]): UserBranchAccess[] {
    const usersWithBranchAccessAll: UserBranchAccess[] = [];
    users.forEach((user) => {
      usersWithBranchAccessAll.push(this.transformToUserWithGroupedRolesBasedByBranchAccess(user));
    });

    return usersWithBranchAccessAll;
  }

  private transformToUserWithGroupedRolesBasedByBranchAccess(user: User): UserBranchAccess {
    const rolesWithBranchAccessAll: Role[] = [];
    const rolesWithBranchAccessSingle: Role[] = [];
    user.role_associations?.forEach((roleAssociation) => {
      roleAssociation.roles?.forEach((role) => {
        if (
          role.branch_access === 'ALL' ||
          [UsersType.ACTIVE, UsersType.INACTIVE].includes(this.activeUsersTypeTab() as UsersType)
        ) {
          if (!rolesWithBranchAccessAll?.find((r) => r.name === role.name)) {
            rolesWithBranchAccessAll.push(role);
          }
        }
        if (role.branch_access === 'BRANCH_SINGLE') {
          if (!rolesWithBranchAccessSingle?.find((r) => r.name === role.name)) {
            rolesWithBranchAccessSingle.push(role);
          }
        }
      });
    });
    // Sort roles in rolesWithBranchAccessAll by name alphabetically
    rolesWithBranchAccessAll.sort((a, b) => {
      if (a.name === undefined) return 1;
      if (b.name === undefined) return -1;
      return a.name.localeCompare(b.name);
    });
    rolesWithBranchAccessSingle.sort((a, b) => {
      if (a.name === undefined) return 1;
      if (b.name === undefined) return -1;
      return a.name.localeCompare(b.name);
    });

    return {
      ...user,
      branchAccessRoles: rolesWithBranchAccessAll,
      singleBranchAccessRoles: rolesWithBranchAccessSingle,
    };
  }

  private constructUserTypeTabs(
    hasBranchCollectionTypeGroup: boolean,
    isSingleBranchType: boolean
  ): void {
    if (isSingleBranchType) {
      this.setUserTypeTabs([UsersType.ACTIVE, UsersType.INACTIVE]);
    } else {
      if (hasBranchCollectionTypeGroup) {
        this.setUserTypeTabs([UsersType.INTERNAL, UsersType.EXTERNAL]);
      } else {
        this.setUserTypeTabs([UsersType.EXTERNAL]);
      }
    }
  }

  private constructUserObj(branchGroups: BranchGroup[]): void {
    let usersTabObj: UsersTabValue = {};

    this.usersTypeTabs().forEach((tab) => {
      usersTabObj = {
        ...usersTabObj,
        ...(tab === UsersType.INTERNAL && { [tab]: null }),
        ...(tab === UsersType.ACTIVE && { [tab]: null }),
        ...(tab === UsersType.INACTIVE && { [tab]: null }),
        ...(tab === UsersType.EXTERNAL && {
          [tab]: {
            ...Object.assign(
              {},
              ...branchGroups.map((branch) => ({
                [branch.id as string]: {
                  ...Object.assign(
                    {},
                    ...(branch?.branches ?? []).map((singleBranch) => ({
                      [singleBranch.id as string]: null,
                    }))
                  ),
                },
              }))
            ),
          },
        }),
      };
    });

    this.setUsers(usersTabObj);
  }

  private constructRolesObj(): void {
    let rolesTabObj: { [key: string]: Role[] } = {};
    this.usersTypeTabs().forEach((tab) => {
      rolesTabObj = {
        ...rolesTabObj,
        ...(tab === UsersType.INTERNAL && { [tab]: [] }),
        ...(tab === UsersType.EXTERNAL && { [tab]: [] }),
        ...(tab === UsersType.ACTIVE && { [tab]: [] }),
        ...(tab === UsersType.INACTIVE && { [tab]: [] }),
      };
    });
    this.setRoles(rolesTabObj);
  }

  private constructUsersSearchQueryObj(branchGroups: BranchGroup[]): void {
    const singleBranches: Branch[] = ([] as Branch[]).concat(
      ...branchGroups.map((branch) => branch?.branches)
    );
    const singleBranch = this.getSingleBranchGroupId(
      this.selectedBranch() ?? ({} as Branch),
      // @ts-ignore
      branchGroups?.[this.activeBranchCollectionTabIndex()]?.branches
    );
    const usersSearch = [UsersType.INACTIVE, UsersType.INACTIVE, UsersType.INTERNAL].includes(
      this.activeUsersTypeTab() as UsersType
    )
      ? this.usersSearchQuery()[this.activeUsersTypeTab() as UsersType]
      : this.usersSearchQuery()?.[singleBranch];

    let usersSearchQueryObj: UsersSearchQueryObj = {};
    this.usersTypeTabs().forEach((tab) => {
      usersSearchQueryObj = {
        ...usersSearchQueryObj,
        ...(tab === UsersType.INTERNAL && { [tab]: usersSearch ?? '' }),
        ...(tab === UsersType.ACTIVE && { [tab]: usersSearch ?? '' }),
        ...(tab === UsersType.INACTIVE && { [tab]: usersSearch ?? '' }),
        ...(tab === UsersType.EXTERNAL && {
          ...Object.assign(
            {},
            ...singleBranches.map((branch) => ({ [branch.id as string]: usersSearch ?? '' }))
          ),
        }),
      };
    });
    this.setUsersSearchQuery(usersSearchQueryObj);
  }

  private constructBranchesSearchQueryObj(branchGroups: BranchGroup[]): void {
    const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const activeBranchGroupId = this.getGroupBranchId(branchGroup, branchGroups);
      const branchesSearch = this.branchesSearchQuery()?.[activeBranchGroupId];
      let branchesSearchQueryObj: BranchesSearchQueryObj = {};

      branchGroups.forEach((branch) => {
        branchesSearchQueryObj = {
          ...branchesSearchQueryObj,
          [branch.id as string]: branchesSearch ?? '',
        };
      });
      this.setBranchesSearchQuery(branchesSearchQueryObj);
    }
  }

  private constructActiveSingleBranchObj(branchGroups: BranchGroup[], value: number): void {
    branchGroups.forEach((branch, index) => {
      this.updateSingleBranchIndex(index, value);
    });
  }

  private fetchRoles(
    branchAccess: 'ALL' | 'BRANCH_SINGLE',
    usersType: UsersType | undefined
  ): void {
    this.rolesService.getRoles().subscribe({
      next: (response) => {
        if (response && response.roles && usersType) {
          if (![UsersType.ACTIVE, UsersType.INACTIVE].includes(usersType)) {
            const roles = response.roles.filter((role) => role.branch_access === branchAccess);
            this.updateRoles(usersType, roles);
          } else {
            this.updateRoles(usersType, response.roles);
          }
        }
      },
      error: (error) => {
        console.error(`[UserService][getRoles]`, error);
      },
    });
  }

  private fetchSkills(): void {
    this.skillsService.getSkills().subscribe({
      next: (response) => {
        if (response && response.skills) {
          this._skills.set(response.skills);
        }
      },
      error: (error) => {
        console.error(`[UserService][getSkills]`, error);
      },
    });
  }

  private setUsers(userObj: UsersTabValue): void {
    this._users.set(userObj);
  }

  private setRoles(roles: { [key: string]: Role[] }): void {
    this._roles.set(roles);
  }

  private setUsersSearchQuery(usersSearchQuery: { [key: string]: string }): void {
    this._usersSearchQuery.set(usersSearchQuery);
  }

  private setBranchesSearchQuery(branchesSearchQuery: { [key: string]: string }): void {
    this._branchesSearchQuery.set(branchesSearchQuery);
  }

  private setActiveUsersTypeIndex(activeUsersTypeTabIndex: number): void {
    this._activeUsersTypeTabIndex.set(activeUsersTypeTabIndex);
  }

  private setActiveBranchGroupsIndex(activeBranchGroupIndex: number): void {
    this._activeBranchCollectionTabIndex.set(activeBranchGroupIndex);
  }

  private setLoadingState(loading: boolean): void {
    this._loading.set(loading);
  }

  private setUserTypeTabs(userTabs: UsersType[]): void {
    this._usersTypeTabs.set(userTabs);
  }

  private setBranches(branches: Branch[]): void {
    this._branches.set(branches);
  }

  private updateUsers(
    res: UserBranchAccess[],
    activeBranchGroupId: string,
    singleBranchGroupId: string
  ) {
    this._users.update((users) => {
      return {
        ...users,
        ...([UsersType.INTERNAL, UsersType.ACTIVE, UsersType.INACTIVE].includes(
          this.activeUsersTypeTab() as UsersType
        ) &&
          ({
            [this.activeUsersTypeTab() as UsersType]: res,
          } as InternalUsersTabValue)),
        ...(this.activeUsersTypeTab() === UsersType.EXTERNAL &&
          ({
            [UsersType.EXTERNAL]: {
              ...(users as ExternalUsersTabValue)[UsersType.EXTERNAL],
              [activeBranchGroupId]: {
                ...((users as ExternalUsersTabValue)[UsersType.EXTERNAL]?.[activeBranchGroupId] ??
                  []),
                [singleBranchGroupId]: res,
              },
            },
          } as ExternalUsersTabValue)),
      } as UsersTabValue;
    });
  }

  private updateSingleBranchIndex(key: number, value: number): void {
    this._activeBranchSingleIndex.update((activeBranchIndex) => ({
      ...activeBranchIndex,
      [key]: value,
    }));
  }

  private updateUsersSearchQuery(key: string | undefined, value: string): void {
    if (key) {
      this._usersSearchQuery.update((usersSearchQuery) => ({
        ...usersSearchQuery,
        [key]: value,
      }));
    }
  }

  private updateBranchesSearchQuery(key: string, value: string): void {
    this._branchesSearchQuery.update((branchesSearchQuery) => ({
      ...branchesSearchQuery,
      [key]: value,
    }));
  }

  private updateRoles(key: string, value: Role[]): void {
    this._roles.update((r) => ({
      ...r,
      [key]: value,
    }));
  }

  private writeAsURLParams(groupBranches: BranchGroup[]): void {
    const branchGroup = groupBranches[this.activeBranchCollectionTabIndex()];
    if (branchGroup) {
      const groupBranch = this.getGroupBranchId(branchGroup, groupBranches);
      const singleBranch = this.getSingleBranchGroupId(
        this.selectedBranch() ?? ({} as Branch),
        // @ts-ignore
        groupBranches[this.activeBranchCollectionTabIndex()]?.branches
      );
      const usersSearch =
        this.activeUsersTypeTab() === UsersType.INTERNAL
          ? this.usersSearchQuery()?.[UsersType.INTERNAL]
          : this.usersSearchQuery()?.[singleBranch];
      const singleBranchSearch = this.branchesSearchQuery()?.[groupBranch];
      const usersType = this.activeUsersTypeTab();
      if (groupBranch != undefined && singleBranch != undefined && usersType != undefined) {
        let params = new HttpParams();
        params =
          this.activeUsersTypeTab() === UsersType.EXTERNAL
            ? params.append('groupBranch', groupBranch)
            : params;
        params =
          this.activeUsersTypeTab() === UsersType.EXTERNAL
            ? params.append('singleBranch', singleBranch)
            : params;

        params = params.append('usersType', usersType);
        params = singleBranchSearch
          ? params.append('singleBranchSearch', singleBranchSearch)
          : params;
        params = usersSearch ? params.append('usersSearch', usersSearch) : params;
        const splitedUrl = this.router.url.split('?')[0];
        if (splitedUrl) {
          this.location.go(splitedUrl, params.toString());
        }
        this._urlParams.set({
          groupBranch,
          singleBranch,
          usersType,
          singleBranchSearch: singleBranchSearch ?? '',
          usersSearch: usersSearch ?? '',
        });
      }
    }
  }

  private getCurrentUsers(
    branchGroups: BranchGroup[],
    singleBranchGroups: BranchGroup[]
  ): UserBranchAccess[] | null | undefined {
    if (
      [UsersType.ACTIVE, UsersType.INACTIVE, UsersType.INTERNAL].includes(
        this.activeUsersTypeTab() as UsersType
      )
    ) {
      return this.users()?.[this.activeUsersTypeTab() as UsersType] as UserBranchAccess[];
    } else {
      const branchGroup = branchGroups[this.activeBranchCollectionTabIndex()];
      if (branchGroup) {
        const activeBranchGroupId = this.getGroupBranchId(branchGroup, branchGroups);
        const singleBranchGroupId = this.getSingleBranchGroupId(
          this.selectedBranch() ?? ({} as Branch),
          singleBranchGroups
        );
        const currentUsers = (this.users() as ExternalUsersTabValue)?.[UsersType.EXTERNAL]?.[
          activeBranchGroupId
        ]?.[singleBranchGroupId];

        return currentUsers;
      }
    }

    return undefined;
  }

  reset(): void {
    this._users.set({});
    this._roles.set({});
    this._branches.set([]);
  }
}
