import { Injectable, Injector, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LoginResponse } from '../interfaces/auth.interface';
import { Store } from '@ngrx/store';
import { AuthState } from '../store/reducers/auth.reducer';
import { Login, SetStatus } from '../store/actions/auth.action';
import { Status } from '../interfaces/status.enum';
import { WebSocketsService } from '../services/web-sockets/web-sockets.service';
import { AuthUserResponse } from '@dispo-shared/open-api/models';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private injector = inject(Injector);
  private authStore = inject<Store<AuthState>>(Store);
  private socketService = inject(WebSocketsService);

  public refreshTokenInProgress = false;
  public refreshTokenSubject: BehaviorSubject<string | AuthUserResponse | null> = new BehaviorSubject<string | AuthUserResponse | null>(null);
  private authService!: AuthService;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response) => {
        let editedResponse = response;

        // This is a workaround when the error property is string instead of a JSON
        if (response instanceof HttpErrorResponse) {
          if (typeof response.error === 'string' && this.isJsonString(response.error)) {
            editedResponse = new HttpErrorResponse({
              error: JSON.parse(response.error),
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
              url: response.url || undefined,
            });
          }
        }

        if (
          editedResponse instanceof HttpErrorResponse &&
          editedResponse.status === 401 &&
          !request.url.includes('/auth')
        ) {
          if (editedResponse?.error?.code === '100026') {
            return throwError(() => editedResponse?.error);
          } else {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter((result) => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              this.authService = this.injector.get(AuthService);
              return this.authService.refreshAccessToken().pipe(
                switchMap((refreshTokenResponse: AuthUserResponse | string) => {
                  if (
                    typeof refreshTokenResponse !== 'string' &&
                    refreshTokenResponse.access_token &&
                    refreshTokenResponse.access_token.valid_until &&
                    refreshTokenResponse.access_token.value &&
                    refreshTokenResponse.refresh_token &&
                    refreshTokenResponse.refresh_token.valid_until &&
                    refreshTokenResponse.refresh_token.value &&
                    refreshTokenResponse.id
                  ) {
                    this.authStore.dispatch(
                      new Login({ tokens: refreshTokenResponse as LoginResponse, refresh: false })
                    );
                    this.authStore.dispatch(new SetStatus({ status: Status.Success }));
                  }
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(refreshTokenResponse);

                  return next.handle(this.addAuthenticationToken(request));
                }),
                catchError((err) => {
                  this.refreshTokenInProgress = false;
                  this.authService.logout();
                  this.socketService.disconnect();

                  return throwError(() => err);
                })
              );
            }
          }
        }
        return throwError(() => editedResponse);
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    this.authService = this.injector.get(AuthService);
    const accessToken = this.authService.getAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
