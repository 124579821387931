export abstract class UrlHelper {
  public static windowLocationIsLocalhost(): boolean {
    return window.location.href.includes('localhost');
  }

  public static firstSubdomainFromWindowLocation(): string | null {
    const host = window.location.hostname;
    const parts = host.split('.');

    // Check if there's a subdomain (e.g., subdomain.example.com)
    if (parts.length >= 2) {
      return parts[0] as string; // The first part is the subdomain
    }

    return null;
  }
}
