/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BranchListResponse } from '../../models/branch-list-response';

export interface GetBranches$Params {

/**
 * a parameter to include an information about drivers_total and drivers_on_duty to a response
 */
  include_drivers_info?: boolean;

/**
 * a filter parameter to filter out branches of all other types
 */
  type?: string | null;
}

export function getBranches(http: HttpClient, rootUrl: string, params?: GetBranches$Params, context?: HttpContext): Observable<StrictHttpResponse<BranchListResponse>> {
  const rb = new RequestBuilder(rootUrl, getBranches.PATH, 'get');
  if (params) {
    rb.query('include_drivers_info', params.include_drivers_info, {"style":"form","explode":true});
    rb.query('type', params.type, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BranchListResponse>;
    })
  );
}

getBranches.PATH = '/api/v1/branches';
