import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  private auth = inject(AuthService);
  private router = inject(Router);

  canActivate(url: string): boolean {
    if (!this.auth.getAccessToken() || !this.auth.getRefreshToken()) {
      if (url) {
        this.auth.lastUrlBeforeLogin = url;
      }
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(PermissionsService).canActivate(state.url);
};
