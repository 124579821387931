export abstract class EnvHelper {
  public static getWebEnv(): string {
    let activeEnvironment = '';
    const url = window.location.host;
    const shortCode = url.split('.')[0]?.split(':')[0];
    const possibleEnvironments = ['dev', 'int', 'staging', 'localhost', 'local', '127.0.0.1'];
    const foundEnvironments = possibleEnvironments.filter(
      (e) => shortCode === e || url.includes(`.${e}.`)
    );

    if (foundEnvironments.length > 0) {
      activeEnvironment = foundEnvironments[0] as string;
    }

    if (foundEnvironments.includes('localhost') || foundEnvironments.includes('local') || foundEnvironments.includes('127.0.0.1')) {
      activeEnvironment = 'local';
    }

    return activeEnvironment || 'production'
  }
}
