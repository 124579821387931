/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OeamtcCaseUpdateRequestResponse } from '../../models/oeamtc-case-update-request-response';

export interface UpdateRoadsideAssistanceCase$Params {

/**
 * External id of a resource
 */
  id: number;
      body: OeamtcCaseUpdateRequestResponse
}

export function updateRoadsideAssistanceCase(http: HttpClient, rootUrl: string, params: UpdateRoadsideAssistanceCase$Params, context?: HttpContext): Observable<StrictHttpResponse<OeamtcCaseUpdateRequestResponse>> {
  const rb = new RequestBuilder(rootUrl, updateRoadsideAssistanceCase.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OeamtcCaseUpdateRequestResponse>;
    })
  );
}

updateRoadsideAssistanceCase.PATH = '/api/v1/extensions/tenants/oeamtc/cases/{id}';
