import * as fromAuth from './reducers/auth.reducer';
import * as fromCases from './reducers/cases.reducer';
import * as fromDevices from './reducers/devices.reducer';
import * as fromDeviceSidebars from './reducers/device-sidebar.reducer';
import * as fromRoles from './reducers/roles.reducer';
import * as fromUsers from './reducers/users.reducer';
import * as fromUserDialogs from './reducers/user-dialogs.reducer';
import * as fromVehicles from './reducers/vehicles.reducer';
import * as fromVehicleSidebar from './reducers/vehicle-sidebar.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  auth: fromAuth.AuthState;
  devices: fromDevices.DevicesState;
  cases: fromCases.CasesState;
  deviceSidebars: fromDeviceSidebars.DeviceSidebarsState;
  roles: fromRoles.RolesState;
  users: fromUsers.UsersState;
  userDialogs: fromUserDialogs.UserDialogsState;
  vehicles: fromVehicles.VehiclesState;
  vehicleSidebar: fromVehicleSidebar.VehicleSidebarState;
}

export const reducers: ActionReducerMap<AppState, never> = {
  auth: fromAuth.authReducer,
  cases: fromCases.casesReducer,
  devices: fromDevices.devicesReducer,
  deviceSidebars: fromDeviceSidebars.deviceSidebarsReducer,
  roles: fromRoles.rolesReducer,
  users: fromUsers.usersReducer,
  userDialogs: fromUserDialogs.userDialogsReducer,
  vehicles: fromVehicles.vehiclesReducer,
  vehicleSidebar: fromVehicleSidebar.vehicleSidebarReducer,
};
