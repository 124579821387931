/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CalendarGroup } from '../../models/calendar-group';

export interface GetCalendarGroupById$Params {

/**
 * Id of a resource.
 */
  id: string;
}

export function getCalendarGroupById(http: HttpClient, rootUrl: string, params: GetCalendarGroupById$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarGroup>> {
  const rb = new RequestBuilder(rootUrl, getCalendarGroupById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CalendarGroup>;
    })
  );
}

getCalendarGroupById.PATH = '/api/v1/calendars/groups/{id}';
