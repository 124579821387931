/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Case } from '../../models/case';
import { CaseUpdateRequest } from '../../models/case-update-request';

export interface FinishCase$Params {

/**
 * Id of a resource.
 */
  id: string;

/**
 * the code of the finish reason to define next work flow.
 */
  finish_reason_code: number;

/**
 * the ID of the tour case is currently associated with.
 */
  tour_id: string;
      body: CaseUpdateRequest
}

export function finishCase(http: HttpClient, rootUrl: string, params: FinishCase$Params, context?: HttpContext): Observable<StrictHttpResponse<Case>> {
  const rb = new RequestBuilder(rootUrl, finishCase.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.query('finish_reason_code', params.finish_reason_code, {"style":"form","explode":true});
    rb.query('tour_id', params.tour_id, {"style":"form","explode":true});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Case>;
    })
  );
}

finishCase.PATH = '/api/v1/cases/{id}/finish';
