/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateSkillRequest } from '../../models/create-skill-request';
import { Skill } from '../../models/skill';

export interface CreateSkill$Params {
      body: CreateSkillRequest
}

export function createSkill(http: HttpClient, rootUrl: string, params: CreateSkill$Params, context?: HttpContext): Observable<StrictHttpResponse<Skill>> {
  const rb = new RequestBuilder(rootUrl, createSkill.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Skill>;
    })
  );
}

createSkill.PATH = '/api/v1/skills';
