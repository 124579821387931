/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteAssetById } from '../fn/assets/delete-asset-by-id';
import { DeleteAssetById$Params } from '../fn/assets/delete-asset-by-id';
import { getAssetById } from '../fn/assets/get-asset-by-id';
import { GetAssetById$Params } from '../fn/assets/get-asset-by-id';

@Injectable({ providedIn: 'root' })
export class AssetsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAssetById()` */
  static readonly GetAssetByIdPath = '/api/v1/assets/{id}';

  /**
   * Get asset by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetById$Response(params: GetAssetById$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return getAssetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get asset by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAssetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetById(params: GetAssetById$Params, context?: HttpContext): Observable<{
}> {
    return this.getAssetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `deleteAssetById()` */
  static readonly DeleteAssetByIdPath = '/api/v1/assets/{id}';

  /**
   * delete asset by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAssetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssetById$Response(params: DeleteAssetById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAssetById(this.http, this.rootUrl, params, context);
  }

  /**
   * delete asset by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAssetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAssetById(params: DeleteAssetById$Params, context?: HttpContext): Observable<void> {
    return this.deleteAssetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
