/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CaseCommentListResponse } from '../../models/case-comment-list-response';

export interface GetCaseComments$Params {

/**
 * Id of a resource.
 */
  id: string;

/**
 * Pagination parameter. Skips page * size entries.
 */
  page?: number;

/**
 * Max number of results per page.
 */
  size?: number;

/**
 * Paramter to sort by as field(:(asc|desc))?. If missing default sort is used. field is the name in the db model not the db field name.
 */
  sort?: Array<string>;
}

export function getCaseComments(http: HttpClient, rootUrl: string, params: GetCaseComments$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseCommentListResponse>> {
  const rb = new RequestBuilder(rootUrl, getCaseComments.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.query('page', params.page, {"style":"form","explode":true});
    rb.query('size', params.size, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CaseCommentListResponse>;
    })
  );
}

getCaseComments.PATH = '/api/v1/cases/{id}/comment';
