/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TenantFrontendConfig } from '../../models/tenant-frontend-config';
import { TenantFrontendConfigUpdate } from '../../models/tenant-frontend-config-update';

export interface UpdateFrontendConfig$Params {

/**
 * The shortcode of the tenant
 */
  short_code: string;
      body: TenantFrontendConfigUpdate
}

export function updateFrontendConfig(http: HttpClient, rootUrl: string, params: UpdateFrontendConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantFrontendConfig>> {
  const rb = new RequestBuilder(rootUrl, updateFrontendConfig.PATH, 'put');
  if (params) {
    rb.path('short_code', params.short_code, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TenantFrontendConfig>;
    })
  );
}

updateFrontendConfig.PATH = '/api/v1/tenants/config/{short_code}/frontend';
