/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createSkill } from '../fn/skills/create-skill';
import { CreateSkill$Params } from '../fn/skills/create-skill';
import { deleteSkill } from '../fn/skills/delete-skill';
import { DeleteSkill$Params } from '../fn/skills/delete-skill';
import { getSkill } from '../fn/skills/get-skill';
import { GetSkill$Params } from '../fn/skills/get-skill';
import { getSkills } from '../fn/skills/get-skills';
import { GetSkills$Params } from '../fn/skills/get-skills';
import { Skill } from '../models/skill';
import { SkillListResponse } from '../models/skill-list-response';
import { updateSkill } from '../fn/skills/update-skill';
import { UpdateSkill$Params } from '../fn/skills/update-skill';

@Injectable({ providedIn: 'root' })
export class SkillsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getSkills()` */
  static readonly GetSkillsPath = '/api/v1/skills';

  /**
   * Get  list of this resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSkills()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSkills$Response(params?: GetSkills$Params, context?: HttpContext): Observable<StrictHttpResponse<SkillListResponse>> {
    return getSkills(this.http, this.rootUrl, params, context);
  }

  /**
   * Get  list of this resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSkills$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSkills(params?: GetSkills$Params, context?: HttpContext): Observable<SkillListResponse> {
    return this.getSkills$Response(params, context).pipe(
      map((r: StrictHttpResponse<SkillListResponse>): SkillListResponse => r.body)
    );
  }

  /** Path part for operation `createSkill()` */
  static readonly CreateSkillPath = '/api/v1/skills';

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSkill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSkill$Response(params: CreateSkill$Params, context?: HttpContext): Observable<StrictHttpResponse<Skill>> {
    return createSkill(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSkill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSkill(params: CreateSkill$Params, context?: HttpContext): Observable<Skill> {
    return this.createSkill$Response(params, context).pipe(
      map((r: StrictHttpResponse<Skill>): Skill => r.body)
    );
  }

  /** Path part for operation `getSkill()` */
  static readonly GetSkillPath = '/api/v1/skills/{skill_id}';

  /**
   * Fetch single skill by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSkill()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSkill$Response(params: GetSkill$Params, context?: HttpContext): Observable<StrictHttpResponse<Skill>> {
    return getSkill(this.http, this.rootUrl, params, context);
  }

  /**
   * Fetch single skill by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSkill$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSkill(params: GetSkill$Params, context?: HttpContext): Observable<Skill> {
    return this.getSkill$Response(params, context).pipe(
      map((r: StrictHttpResponse<Skill>): Skill => r.body)
    );
  }

  /** Path part for operation `updateSkill()` */
  static readonly UpdateSkillPath = '/api/v1/skills/{skill_id}';

  /**
   * Update an existing resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSkill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSkill$Response(params: UpdateSkill$Params, context?: HttpContext): Observable<StrictHttpResponse<Skill>> {
    return updateSkill(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an existing resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateSkill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSkill(params: UpdateSkill$Params, context?: HttpContext): Observable<Skill> {
    return this.updateSkill$Response(params, context).pipe(
      map((r: StrictHttpResponse<Skill>): Skill => r.body)
    );
  }

  /** Path part for operation `deleteSkill()` */
  static readonly DeleteSkillPath = '/api/v1/skills/{skill_id}';

  /**
   * Delete an existing skill.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSkill()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSkill$Response(params: DeleteSkill$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteSkill(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an existing skill.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSkill$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSkill(params: DeleteSkill$Params, context?: HttpContext): Observable<void> {
    return this.deleteSkill$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
