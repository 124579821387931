import { Component } from '@angular/core';

@Component({
  selector: 'xyz-shared-icons',
  standalone: true,
  imports: [],
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent {}
