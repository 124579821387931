/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CalendarGroup } from '../models/calendar-group';
import { CalendarGroupListResponse } from '../models/calendar-group-list-response';
import { createCalendarGroup } from '../fn/calendar-groups/create-calendar-group';
import { CreateCalendarGroup$Params } from '../fn/calendar-groups/create-calendar-group';
import { deleteCalendarGroup } from '../fn/calendar-groups/delete-calendar-group';
import { DeleteCalendarGroup$Params } from '../fn/calendar-groups/delete-calendar-group';
import { getCalendarGroupById } from '../fn/calendar-groups/get-calendar-group-by-id';
import { GetCalendarGroupById$Params } from '../fn/calendar-groups/get-calendar-group-by-id';
import { getCalendarGroups } from '../fn/calendar-groups/get-calendar-groups';
import { GetCalendarGroups$Params } from '../fn/calendar-groups/get-calendar-groups';
import { updateCalendarGroup } from '../fn/calendar-groups/update-calendar-group';
import { UpdateCalendarGroup$Params } from '../fn/calendar-groups/update-calendar-group';

@Injectable({ providedIn: 'root' })
export class CalendarGroupsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCalendarGroups()` */
  static readonly GetCalendarGroupsPath = '/api/v1/calendars/groups';

  /**
   * Get all calendar groups for tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarGroups$Response(params?: GetCalendarGroups$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarGroupListResponse>> {
    return getCalendarGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all calendar groups for tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalendarGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarGroups(params?: GetCalendarGroups$Params, context?: HttpContext): Observable<CalendarGroupListResponse> {
    return this.getCalendarGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarGroupListResponse>): CalendarGroupListResponse => r.body)
    );
  }

  /** Path part for operation `createCalendarGroup()` */
  static readonly CreateCalendarGroupPath = '/api/v1/calendars/groups';

  /**
   * Create new calendar group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalendarGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarGroup$Response(params: CreateCalendarGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarGroup>> {
    return createCalendarGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new calendar group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalendarGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarGroup(params: CreateCalendarGroup$Params, context?: HttpContext): Observable<CalendarGroup> {
    return this.createCalendarGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarGroup>): CalendarGroup => r.body)
    );
  }

  /** Path part for operation `getCalendarGroupById()` */
  static readonly GetCalendarGroupByIdPath = '/api/v1/calendars/groups/{id}';

  /**
   * Get calendar group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarGroupById$Response(params: GetCalendarGroupById$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarGroup>> {
    return getCalendarGroupById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get calendar group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalendarGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarGroupById(params: GetCalendarGroupById$Params, context?: HttpContext): Observable<CalendarGroup> {
    return this.getCalendarGroupById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarGroup>): CalendarGroup => r.body)
    );
  }

  /** Path part for operation `updateCalendarGroup()` */
  static readonly UpdateCalendarGroupPath = '/api/v1/calendars/groups/{id}';

  /**
   * Update calendar group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCalendarGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarGroup$Response(params: UpdateCalendarGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarGroup>> {
    return updateCalendarGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Update calendar group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCalendarGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarGroup(params: UpdateCalendarGroup$Params, context?: HttpContext): Observable<CalendarGroup> {
    return this.updateCalendarGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarGroup>): CalendarGroup => r.body)
    );
  }

  /** Path part for operation `deleteCalendarGroup()` */
  static readonly DeleteCalendarGroupPath = '/api/v1/calendars/groups/{id}';

  /**
   * Delete calendar group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalendarGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarGroup$Response(params: DeleteCalendarGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCalendarGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete calendar group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCalendarGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarGroup(params: DeleteCalendarGroup$Params, context?: HttpContext): Observable<void> {
    return this.deleteCalendarGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
