/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authDevice } from '../fn/authentication/auth-device';
import { AuthDevice$Params } from '../fn/authentication/auth-device';
import { AuthDeviceResponse } from '../models/auth-device-response';
import { authDeviceVerify } from '../fn/authentication/auth-device-verify';
import { AuthDeviceVerify$Params } from '../fn/authentication/auth-device-verify';
import { authUser } from '../fn/authentication/auth-user';
import { AuthUser$Params } from '../fn/authentication/auth-user';
import { authUserPin } from '../fn/authentication/auth-user-pin';
import { AuthUserPin$Params } from '../fn/authentication/auth-user-pin';
import { AuthUserPinResponse } from '../models/auth-user-pin-response';
import { AuthUserResponse } from '../models/auth-user-response';
import { authUserWithPhoneNumber } from '../fn/authentication/auth-user-with-phone-number';
import { AuthUserWithPhoneNumber$Params } from '../fn/authentication/auth-user-with-phone-number';
import { authUserWithPhoneNumberVerify } from '../fn/authentication/auth-user-with-phone-number-verify';
import { AuthUserWithPhoneNumberVerify$Params } from '../fn/authentication/auth-user-with-phone-number-verify';
import { CoolDownTimeResponse } from '../models/cool-down-time-response';
import { requestPasswordReset } from '../fn/authentication/request-password-reset';
import { RequestPasswordReset$Params } from '../fn/authentication/request-password-reset';
import { setPassword } from '../fn/authentication/set-password';
import { SetPassword$Params } from '../fn/authentication/set-password';
import { validateToken } from '../fn/authentication/validate-token';
import { ValidateToken$Params } from '../fn/authentication/validate-token';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authDevice()` */
  static readonly AuthDevicePath = '/api/v1/auth/device';

  /**
   * This call sends the telephone number of the device. The backend verifies the number by sending a SMS.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authDevice$Response(params: AuthDevice$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthDeviceResponse>> {
    return authDevice(this.http, this.rootUrl, params, context);
  }

  /**
   * This call sends the telephone number of the device. The backend verifies the number by sending a SMS.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authDevice(params: AuthDevice$Params, context?: HttpContext): Observable<AuthDeviceResponse> {
    return this.authDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthDeviceResponse>): AuthDeviceResponse => r.body)
    );
  }

  /** Path part for operation `authDeviceVerify()` */
  static readonly AuthDeviceVerifyPath = '/api/v1/auth/device/verify';

  /**
   * This call verifies the telephone number and the SMS code and answers with a DEVICE token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authDeviceVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authDeviceVerify$Response(params: AuthDeviceVerify$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthDeviceResponse>> {
    return authDeviceVerify(this.http, this.rootUrl, params, context);
  }

  /**
   * This call verifies the telephone number and the SMS code and answers with a DEVICE token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authDeviceVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authDeviceVerify(params: AuthDeviceVerify$Params, context?: HttpContext): Observable<AuthDeviceResponse> {
    return this.authDeviceVerify$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthDeviceResponse>): AuthDeviceResponse => r.body)
    );
  }

  /** Path part for operation `authUser()` */
  static readonly AuthUserPath = '/api/v1/auth/user';

  /**
   * Web login with username and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUser$Response(params: AuthUser$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthUserResponse>> {
    return authUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Web login with username and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUser(params: AuthUser$Params, context?: HttpContext): Observable<AuthUserResponse> {
    return this.authUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthUserResponse>): AuthUserResponse => r.body)
    );
  }

  /** Path part for operation `requestPasswordReset()` */
  static readonly RequestPasswordResetPath = '/api/v1/auth/user/password/reset/request';

  /**
   * This call sends the email address of the user that requested a password reset.The backend send an email with the link to the reveiced email address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestPasswordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestPasswordReset$Response(params: RequestPasswordReset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestPasswordReset(this.http, this.rootUrl, params, context);
  }

  /**
   * This call sends the email address of the user that requested a password reset.The backend send an email with the link to the reveiced email address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestPasswordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  requestPasswordReset(params: RequestPasswordReset$Params, context?: HttpContext): Observable<void> {
    return this.requestPasswordReset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `setPassword()` */
  static readonly SetPasswordPath = '/api/v1/auth/user/password/reset/set';

  /**
   * This call sends the new password that user submitted and the password reset token. The backend validates the token and then updates the data accordingly.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPassword$Response(params: SetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthUserResponse>> {
    return setPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This call sends the new password that user submitted and the password reset token. The backend validates the token and then updates the data accordingly.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPassword(params: SetPassword$Params, context?: HttpContext): Observable<AuthUserResponse> {
    return this.setPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthUserResponse>): AuthUserResponse => r.body)
    );
  }

  /** Path part for operation `validateToken()` */
  static readonly ValidateTokenPath = '/api/v1/auth/user/password/reset/validate/token';

  /**
   * This call sends a JWT token that backend validates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateToken$Response(params: ValidateToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This call sends a JWT token that backend validates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateToken(params: ValidateToken$Params, context?: HttpContext): Observable<void> {
    return this.validateToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authUserWithPhoneNumber()` */
  static readonly AuthUserWithPhoneNumberPath = '/api/v1/auth/user/phone_number';

  /**
   * Web login with phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authUserWithPhoneNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserWithPhoneNumber$Response(params: AuthUserWithPhoneNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<CoolDownTimeResponse>> {
    return authUserWithPhoneNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Web login with phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authUserWithPhoneNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserWithPhoneNumber(params: AuthUserWithPhoneNumber$Params, context?: HttpContext): Observable<CoolDownTimeResponse> {
    return this.authUserWithPhoneNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoolDownTimeResponse>): CoolDownTimeResponse => r.body)
    );
  }

  /** Path part for operation `authUserWithPhoneNumberVerify()` */
  static readonly AuthUserWithPhoneNumberVerifyPath = '/api/v1/auth/user/phone_number/verify';

  /**
   * This call verifies the telephone number and the SMS code and answers with a USER token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authUserWithPhoneNumberVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserWithPhoneNumberVerify$Response(params: AuthUserWithPhoneNumberVerify$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthUserResponse>> {
    return authUserWithPhoneNumberVerify(this.http, this.rootUrl, params, context);
  }

  /**
   * This call verifies the telephone number and the SMS code and answers with a USER token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authUserWithPhoneNumberVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserWithPhoneNumberVerify(params: AuthUserWithPhoneNumberVerify$Params, context?: HttpContext): Observable<AuthUserResponse> {
    return this.authUserWithPhoneNumberVerify$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthUserResponse>): AuthUserResponse => r.body)
    );
  }

  /** Path part for operation `authUserPin()` */
  static readonly AuthUserPinPath = '/api/v1/auth/user/pin';

  /**
   * Retrieves a user token while being authenticated as a device by using a pin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authUserPin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserPin$Response(params: AuthUserPin$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthUserPinResponse>> {
    return authUserPin(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a user token while being authenticated as a device by using a pin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authUserPin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authUserPin(params: AuthUserPin$Params, context?: HttpContext): Observable<AuthUserPinResponse> {
    return this.authUserPin$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthUserPinResponse>): AuthUserPinResponse => r.body)
    );
  }

}
