/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserInviteListResponse } from '../../models/user-invite-list-response';

export interface GetUserInvites$Params {

/**
 * Show only invites for this user
 */
  invited_user_id?: string;

/**
 * Show only invites created by this user
 */
  invited_by_user_id?: string;
}

export function getUserInvites(http: HttpClient, rootUrl: string, params?: GetUserInvites$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInviteListResponse>> {
  const rb = new RequestBuilder(rootUrl, getUserInvites.PATH, 'get');
  if (params) {
    rb.query('invited_user_id', params.invited_user_id, {"style":"form","explode":true});
    rb.query('invited_by_user_id', params.invited_by_user_id, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserInviteListResponse>;
    })
  );
}

getUserInvites.PATH = '/api/v1/user_invites';
