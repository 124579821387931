/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DeviceInstalledAppInfoUpdate } from '../../models/device-installed-app-info-update';

export interface UpdateInstalledAppInformation$Params {

/**
 * Id of a resource.
 */
  id: string;
      body: DeviceInstalledAppInfoUpdate
}

export function updateInstalledAppInformation(http: HttpClient, rootUrl: string, params: UpdateInstalledAppInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, updateInstalledAppInformation.PATH, 'patch');
  if (params) {
    rb.path('id', params.id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

updateInstalledAppInformation.PATH = '/api/v1/devices/{id}/installed_app_info';
