import { DeviceSidebarsActions, DeviceSidebarsActionTypes } from '../actions/device-sidebar.action';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '../../interfaces/error.interface';

export interface DeviceSidebarsState {
  manageDialogOpenStatus: boolean;
  manageDialogRequestStatus: Status;
  manageDialogError: ErrorResponse | string | null;
  associationSidebarOpenStatus: boolean;
  associationSidebarRequestStatus: Status;
  associationSidebarError: string | null;
}

export const deviceSidebarsInitialState: DeviceSidebarsState = {
  manageDialogOpenStatus: false,
  manageDialogRequestStatus: Status.Default,
  manageDialogError: null,
  associationSidebarOpenStatus: false,
  associationSidebarRequestStatus: Status.Default,
  associationSidebarError: null,
};

export function deviceSidebarsReducer(
  state: DeviceSidebarsState = deviceSidebarsInitialState,
  action: DeviceSidebarsActions
): DeviceSidebarsState {
  switch (action.type) {
    case DeviceSidebarsActionTypes.SetManageDialogOpenStatus: {
      return {
        ...state,
        manageDialogOpenStatus: action.payload.open,
      };
    }

    case DeviceSidebarsActionTypes.SetManageDialogRequestStatus: {
      return {
        ...state,
        manageDialogRequestStatus: action.payload.status,
        manageDialogError: action.payload.error ? action.payload.error : null,
      };
    }

    case DeviceSidebarsActionTypes.SetAssociationSidebarOpenStatus: {
      return {
        ...state,
        associationSidebarOpenStatus: action.payload.open,
      };
    }

    case DeviceSidebarsActionTypes.SetAssociationSidebarRequestStatus: {
      return {
        ...state,
        associationSidebarRequestStatus: action.payload.status,
        associationSidebarError: action.payload.error ? action.payload.error : null,
      };
    }

    default: {
      return state;
    }
  }
}
