/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createAddress } from '../fn/addresses/create-address';
import { CreateAddress$Params } from '../fn/addresses/create-address';
import { deleteAddress } from '../fn/addresses/delete-address';
import { DeleteAddress$Params } from '../fn/addresses/delete-address';
import { getDefaultAddresses } from '../fn/addresses/get-default-addresses';
import { GetDefaultAddresses$Params } from '../fn/addresses/get-default-addresses';
import { StoredAddress } from '../models/stored-address';
import { StoredAddressListResponse } from '../models/stored-address-list-response';
import { updateAddress } from '../fn/addresses/update-address';
import { UpdateAddress$Params } from '../fn/addresses/update-address';

@Injectable({ providedIn: 'root' })
export class AddressesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDefaultAddresses()` */
  static readonly GetDefaultAddressesPath = '/api/v1/addresses';

  /**
   * Get default Addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDefaultAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultAddresses$Response(params?: GetDefaultAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddressListResponse>> {
    return getDefaultAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Get default Addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDefaultAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDefaultAddresses(params?: GetDefaultAddresses$Params, context?: HttpContext): Observable<StoredAddressListResponse> {
    return this.getDefaultAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredAddressListResponse>): StoredAddressListResponse => r.body)
    );
  }

  /** Path part for operation `createAddress()` */
  static readonly CreateAddressPath = '/api/v1/addresses';

  /**
   * Create an address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress$Response(params: CreateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddress>> {
    return createAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAddress(params: CreateAddress$Params, context?: HttpContext): Observable<StoredAddress> {
    return this.createAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredAddress>): StoredAddress => r.body)
    );
  }

  /** Path part for operation `updateAddress()` */
  static readonly UpdateAddressPath = '/api/v1/addresses/{id}';

  /**
   * Update an existing address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress$Response(params: UpdateAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<StoredAddress>> {
    return updateAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Update an existing address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAddress(params: UpdateAddress$Params, context?: HttpContext): Observable<StoredAddress> {
    return this.updateAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<StoredAddress>): StoredAddress => r.body)
    );
  }

  /** Path part for operation `deleteAddress()` */
  static readonly DeleteAddressPath = '/api/v1/addresses/{id}';

  /**
   * Delete an existing address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress$Response(params: DeleteAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an existing address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAddress(params: DeleteAddress$Params, context?: HttpContext): Observable<void> {
    return this.deleteAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
