/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { associateUsersWithTour } from '../fn/scheduled-tours/associate-users-with-tour';
import { AssociateUsersWithTour$Params } from '../fn/scheduled-tours/associate-users-with-tour';
import { associateVehiclesWithTour } from '../fn/scheduled-tours/associate-vehicles-with-tour';
import { AssociateVehiclesWithTour$Params } from '../fn/scheduled-tours/associate-vehicles-with-tour';
import { createScheduledTour } from '../fn/scheduled-tours/create-scheduled-tour';
import { CreateScheduledTour$Params } from '../fn/scheduled-tours/create-scheduled-tour';
import { deleteTour } from '../fn/scheduled-tours/delete-tour';
import { DeleteTour$Params } from '../fn/scheduled-tours/delete-tour';
import { getScheduledTour } from '../fn/scheduled-tours/get-scheduled-tour';
import { GetScheduledTour$Params } from '../fn/scheduled-tours/get-scheduled-tour';
import { Tour } from '../models/tour';
import { updateScheduledTour } from '../fn/scheduled-tours/update-scheduled-tour';
import { UpdateScheduledTour$Params } from '../fn/scheduled-tours/update-scheduled-tour';

@Injectable({ providedIn: 'root' })
export class ScheduledToursService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createScheduledTour()` */
  static readonly CreateScheduledTourPath = '/api/v1/tours/scheduled';

  /**
   * Create new scheduled tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createScheduledTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScheduledTour$Response(params: CreateScheduledTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return createScheduledTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new scheduled tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createScheduledTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createScheduledTour(params: CreateScheduledTour$Params, context?: HttpContext): Observable<Tour> {
    return this.createScheduledTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `getScheduledTour()` */
  static readonly GetScheduledTourPath = '/api/v1/tours/scheduled/{id}';

  /**
   * Get scheduled tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScheduledTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledTour$Response(params: GetScheduledTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return getScheduledTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Get scheduled tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getScheduledTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScheduledTour(params: GetScheduledTour$Params, context?: HttpContext): Observable<Tour> {
    return this.getScheduledTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `deleteTour()` */
  static readonly DeleteTourPath = '/api/v1/tours/scheduled/{id}';

  /**
   * Delete existing scheduled tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTour()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTour$Response(params: DeleteTour$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing scheduled tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTour$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTour(params: DeleteTour$Params, context?: HttpContext): Observable<void> {
    return this.deleteTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateScheduledTour()` */
  static readonly UpdateScheduledTourPath = '/api/v1/tours/scheduled/{id}';

  /**
   * Update scheduled tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScheduledTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScheduledTour$Response(params: UpdateScheduledTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return updateScheduledTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Update scheduled tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateScheduledTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScheduledTour(params: UpdateScheduledTour$Params, context?: HttpContext): Observable<Tour> {
    return this.updateScheduledTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `associateUsersWithTour()` */
  static readonly AssociateUsersWithTourPath = '/api/v1/tours/scheduled/{id}/associated_users';

  /**
   * Associate user with tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateUsersWithTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateUsersWithTour$Response(params: AssociateUsersWithTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return associateUsersWithTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Associate user with tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateUsersWithTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateUsersWithTour(params: AssociateUsersWithTour$Params, context?: HttpContext): Observable<Tour> {
    return this.associateUsersWithTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

  /** Path part for operation `associateVehiclesWithTour()` */
  static readonly AssociateVehiclesWithTourPath = '/api/v1/tours/scheduled/{id}/associated_vehicles';

  /**
   * Associate vehicles with tour.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `associateVehiclesWithTour()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateVehiclesWithTour$Response(params: AssociateVehiclesWithTour$Params, context?: HttpContext): Observable<StrictHttpResponse<Tour>> {
    return associateVehiclesWithTour(this.http, this.rootUrl, params, context);
  }

  /**
   * Associate vehicles with tour.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `associateVehiclesWithTour$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  associateVehiclesWithTour(params: AssociateVehiclesWithTour$Params, context?: HttpContext): Observable<Tour> {
    return this.associateVehiclesWithTour$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tour>): Tour => r.body)
    );
  }

}
