/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CalendarEventListResponse } from '../../models/calendar-event-list-response';

export interface GetCalendarEvents$Params {

/**
 * Start date for included events. Setting this value activates includeEvents=true automatically.
 */
  events_from?: string;

/**
 * End date for included events. Setting this value activates includeEvents=true automatically.
 */
  events_to?: string;

/**
 * Return calendar events with specific resource type.
 */
  resource_type?: string;
}

export function getCalendarEvents(http: HttpClient, rootUrl: string, params?: GetCalendarEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<CalendarEventListResponse>> {
  const rb = new RequestBuilder(rootUrl, getCalendarEvents.PATH, 'get');
  if (params) {
    rb.query('events_from', params.events_from, {"style":"form","explode":true});
    rb.query('events_to', params.events_to, {"style":"form","explode":true});
    rb.query('resource_type', params.resource_type, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CalendarEventListResponse>;
    })
  );
}

getCalendarEvents.PATH = '/api/v1/calendars/default/events';
