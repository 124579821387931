/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTemplate } from '../fn/export/create-template';
import { CreateTemplate$Params } from '../fn/export/create-template';
import { exportCase } from '../fn/export/export-case';
import { ExportCase$Params } from '../fn/export/export-case';
import { exportCasesWithTimeFrame } from '../fn/export/export-cases-with-time-frame';
import { ExportCasesWithTimeFrame$Params } from '../fn/export/export-cases-with-time-frame';
import { exportCasesWithTourId } from '../fn/export/export-cases-with-tour-id';
import { ExportCasesWithTourId$Params } from '../fn/export/export-cases-with-tour-id';
import { ExportOk } from '../models/export-ok';
import { Template } from '../models/template';

@Injectable({ providedIn: 'root' })
export class ExportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportCase()` */
  static readonly ExportCasePath = '/api/v1/export/case/{id}';

  /**
   * Endpoint for exporting a case based on id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCase()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCase$Response(params: ExportCase$Params, context?: HttpContext): Observable<StrictHttpResponse<ExportOk>> {
    return exportCase(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint for exporting a case based on id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCase$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCase(params: ExportCase$Params, context?: HttpContext): Observable<ExportOk> {
    return this.exportCase$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportOk>): ExportOk => r.body)
    );
  }

  /** Path part for operation `createTemplate()` */
  static readonly CreateTemplatePath = '/api/v1/export/template';

  /**
   * Endpoint to save an export template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplate$Response(params: CreateTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<Template>> {
    return createTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint to save an export template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplate(params: CreateTemplate$Params, context?: HttpContext): Observable<Template> {
    return this.createTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Template>): Template => r.body)
    );
  }

  /** Path part for operation `exportCasesWithTimeFrame()` */
  static readonly ExportCasesWithTimeFramePath = '/api/v1/export/time';

  /**
   * Endpoint for exporting cases based on time (from - to).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCasesWithTimeFrame()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCasesWithTimeFrame$Response(params: ExportCasesWithTimeFrame$Params, context?: HttpContext): Observable<StrictHttpResponse<ExportOk>> {
    return exportCasesWithTimeFrame(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint for exporting cases based on time (from - to).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCasesWithTimeFrame$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  exportCasesWithTimeFrame(params: ExportCasesWithTimeFrame$Params, context?: HttpContext): Observable<ExportOk> {
    return this.exportCasesWithTimeFrame$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportOk>): ExportOk => r.body)
    );
  }

  /** Path part for operation `exportCasesWithTourId()` */
  static readonly ExportCasesWithTourIdPath = '/api/v1/export/tour/{id}';

  /**
   * Endpoint for exporting cases based on tour id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportCasesWithTourId()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCasesWithTourId$Response(params: ExportCasesWithTourId$Params, context?: HttpContext): Observable<StrictHttpResponse<ExportOk>> {
    return exportCasesWithTourId(this.http, this.rootUrl, params, context);
  }

  /**
   * Endpoint for exporting cases based on tour id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportCasesWithTourId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportCasesWithTourId(params: ExportCasesWithTourId$Params, context?: HttpContext): Observable<ExportOk> {
    return this.exportCasesWithTourId$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportOk>): ExportOk => r.body)
    );
  }

}
