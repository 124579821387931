/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActiveVehicleListResponse } from '../../models/active-vehicle-list-response';

export interface GetNearestActiveVehicles$Params {

/**
 * Query parameter containing latitude and longitude
 */
  target_location?: Array<number>;

/**
 * Query parameter for sorting by either duration or distance
 */
  sort?: 'duration' | 'distance';
}

export function getNearestActiveVehicles(http: HttpClient, rootUrl: string, params?: GetNearestActiveVehicles$Params, context?: HttpContext): Observable<StrictHttpResponse<ActiveVehicleListResponse>> {
  const rb = new RequestBuilder(rootUrl, getNearestActiveVehicles.PATH, 'get');
  if (params) {
    rb.query('target_location', params.target_location, {"style":"form","explode":true});
    rb.query('sort', params.sort, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ActiveVehicleListResponse>;
    })
  );
}

getNearestActiveVehicles.PATH = '/api/v1/routing/nearest_active_vehicles';
